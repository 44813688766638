import {createSlice} from "@reduxjs/toolkit";
import {addContactAsync, fetchContactAsync, getContactAsync} from "./contactThunk";


const contactSlice = createSlice({
    name: "contact",
    initialState: {
        contact: null,
        contacts: null,
        success: null,
    },
    reducers: {},
    extraReducers: {
        [fetchContactAsync.fulfilled]: (state, {payload}) => {
            return {...state, contacts: payload}
        },
        [getContactAsync.fulfilled]: (state, {payload}) => {
            return {...state, contact: payload}
        },
        [addContactAsync.fulfilled]: (state, {payload}) => {
            return {...state, success: payload}
        },
    },
})

export const getAddContactSuccess = (state) => state.contact.success
export const getContacts = (state) => state.contact.contacts

export default contactSlice.reducer
