import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { orderSelector } from "../../../features/reducer/order/orderSlice";
import moment from "moment";
import {
  extendOrderItemAsync,
  getOrdersAsync,
} from "../../../features/reducer/order/orderThunk";
import {
  FiArrowDown,
  FiArrowUp,
  FiClock,
  FiEdit,
  FiEye,
  FiSearch,
  FiStar,
  FiTrash2,
} from "react-icons/fi";
import DatePicker from "react-datepicker";
import { rowRuning } from "../../../common/functions/usage";
import ReactPaginate from "react-paginate";
import DateThai from "../../utility/DateThai";
import {
  setActiveMenu,
  setOpenModalContent,
} from "../../../features/reducer/utility";
import OrderView from "./OrderView";
import OrderEdit from "./OrderEdit";
import ExcelExport from "../../../services/excelExport";
import { cancelOrders } from "../../../common/api/orderApi";
import { notificationErrorCustomMessage } from "../../../common/config/message";

const Order = () => {
  // Store
  const dispatch = useDispatch();
  const selector = useSelector(orderSelector);

  // useState
  const [search, setSearch] = useState({
    page: 1,
    pageSize: 50,
    startDate: "",
    endDate: "",
    code: ``,
    name: ``,
    sort: `desc`,
    sortBy: ``,
  });

  // UseEffect
  useEffect(() => {
    dispatch(setActiveMenu(`order`));
    handleSearch();
  }, [dispatch]);

  // handle
  const handleSearch = () => {
    let s = {
      ...search,
      page: 1,
      startDate: search.startDate
        ? moment(search.startDate).format(`YYYY-MM-DD`)
        : "",
      endDate: search.endDate
        ? moment(search.endDate).format(`YYYY-MM-DD`)
        : "",
    };
    if (search.code !== `` || search.name !== ``) {
      s.startDate = ``;
      s.endDate = ``;
    }
    setSearch({
      ...search,
      page: 1,
    });
    dispatch(getOrdersAsync(s));
  };

  const handleOrderBy = (sortBy) => {
    let sort = `desc`;
    if (search.sort === `desc`) sort = `asc`;

    setSearch({
      ...search,
      sort: sort,
      sortBy: sortBy,
    });
    const s = {
      ...search,
      sort: sort,
      sortBy: sortBy,
      startDate: search.startDate
        ? moment(search.startDate).format(`YYYY-MM-DD`)
        : "",
      endDate: search.endDate
        ? moment(search.endDate).format(`YYYY-MM-DD`)
        : "",
    };
    dispatch(getOrdersAsync(s));
  };

  const handleDate = (v, name) => {
    let f = { ...search, [name]: v };
    setSearch(f);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handlePageClick = (event) => {
    const page = event.selected + 1;
    const s = { ...search, page: page };
    setSearch(s);
    dispatch(getOrdersAsync(s));
  };

  const handleExtendTime = (id) => {
    if (window.confirm(`ยืนยันขยายเวลา`)) {
      dispatch(
        extendOrderItemAsync({
          id: id,
          search: search,
        }),
      );
    }
  };

  const handleLoadData = (ExcelExport) => {
    let excels = [];
    selector?.orders?.data.map((order) => {
      let row = {};
      const items = order?.items;
      if (items.length > 0) {
        let name = order?.name.split(` `);
        items.map((item) => {
          let status = `ใช้งานแล้ว`;
          if (item?.status !== 1) {
            status = `ยังไม่ใช้งาน`;
          }
          const d = moment(item?.usedDate).format(`YYYY-MM-DD`);
          let dError = false;
          if (d === `0001-01-01` || d === ``) {
            dError = true;
          }

          let payment = `รอชำระเงิน`;
          if (order?.status === `success`) {
            payment = `ชำระเงินแล้ว`;
          }
          let paymentChannel = ``;
          if (order?.status == `success`) {
            paymentChannel = `PromptPay`;
            if (
              typeof order?.payment?.cardNo !== `undefined` &&
              order?.payment?.cardNo !== ``
            ) {
              paymentChannel = `Credit Card`;
            }
          }
          // console.log(order.createdAt)
          row = {
            "Order Date": moment(order.createdAt).format(`YYYY-MM-DD`),
            "Order Time": moment(order.createdAt).format(`HH:mm`),
            "Order RefID": order?.code,
            "Order Bank RefID": order?.payment?.code,
            "Payment Method": `2C2P`,
            "Package Name": item?.name ?? "",
            "Product Code": item?.number || ``,
            Start: item?.packageDate ?? ``,
            End: ``,
            "Product Qty": 1,
            "Product Price":
              item?.pricePerItem * item?.unit -
              (isNaN(item?.discount) ? 0 : item?.discount),
            "Total Price": item?.amount ?? 0,
            Type: 1,
            "Name On Receipt": name[1] ?? "",
            "Surname On Receipt": name[2] ?? "",
            "Email On Receipt": order?.email ?? "",
            "Mobile On Receipt": order?.phone ?? "",
            Used: "",
            Balance: "",
            สถานะ: status ?? "",
            สถานะการชำระเงิน: payment ?? "",
            วันที่ชำระเงิน: order.payment?.transactionDateTime ?? ``,
            ช่องทางการชำระเงิน: paymentChannel ?? ``,
            "Bill No": item?.billNo ?? "",
            "Cashier Name": item?.cashierName ?? "",
            HN: item?.HN ?? "",
            "Patient Name": item?.patientName ?? "",
            "Used DataTime": !dError
              ? moment(item.usedDate).format(`YYYY-MM-DD`)
              : "",
            Memo: item?.memo ?? "",
            Channel: "website",
          };
          // console.log(row)
          excels.push(row);
        });
      }
    });

    ExcelExport(excels);
  };

  const handleCancelOrder = (id) => {
    if (window.confirm(`ยืนยันการยกเลิก`)) {
      cancelOrders(id)
        .then((resp) => {
          if (resp.status === 200) {
            const s = {
              ...search,
              startDate: moment(search.startDate).format(`YYYY-MM-DD`),
              endDate: moment(search.endDate).format(`YYYY-MM-DD`),
            };
            dispatch(getOrdersAsync(s));
          }
        })
        .catch((e) => {
          notificationErrorCustomMessage(`ไม่สามารถยกเลิกได้`, `warning`);
        });
    }
  };

  return (
    <>
      <div className="box box-table">
        <div className="box-header">
          <FiStar /> รายการคำสั่งซื้อ
        </div>
        <div className="row justify-content-between mb-3 mb-lg-0 form-filter">
          <div className="col-lg-12 mb-3 mb-lg-0">
            <div className="d-flex flex-wrap align-items-center">
              <div className="form-group mb-lg-0 mr-2">
                <input
                  type="text"
                  className="form-control"
                  name="code"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleInput(e)}
                  placeholder="ค้นหา รหัสคำสั่งซื้อ"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleInput(e)}
                  placeholder="ค้นหา ชื่อผู้สั่งซื้อ"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่เริ่มต้น"
                  selected={search?.startDate}
                  onChange={(date) => handleDate(date, "startDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่สิ้นสุด"
                  selected={search?.endDate}
                  onChange={(date) => handleDate(date, "endDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <select
                  name="status"
                  onChange={(e) => handleInput(e)}
                  className="form-control"
                >
                  <option value="">สถานะทั้งหมด</option>
                  <option value="pending">รอชำระเงิน</option>
                  <option value="success">ชำระเงินแล้ว</option>
                </select>
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <button
                  onClick={() => handleSearch()}
                  className="btn btn-success"
                >
                  <FiSearch /> ค้นหา
                </button>
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <ExcelExport
                  fileName={`export`}
                  handleLoadData={handleLoadData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive bg-white mb-4">
        <table className="table table-striped  mb-0 table-sm">
          <thead>
            <tr>
              <th className="text-center" width={50}>
                ลำดับที่
              </th>
              <th className="text-center" width={160}>
                <div onClick={() => handleOrderBy(`code`)}>
                  <u>
                    คำสั่งซื้อ{" "}
                    {search.sortBy === `code` && (
                      <>
                        {search.sort === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-left">รายการ</th>
              <th className="text-left" width={200}>
                โดย
              </th>
              {/*<th className="text-left" width={180}>ผู้มาใช้บริการ</th>*/}
              <th className="text-center" width={150}>
                การใช้งาน
              </th>
              <th className="text-center" width={150}>
                <div onClick={() => handleOrderBy(`status`)}>
                  <u>
                    สถานะ{" "}
                    {search.sortBy === `status` && (
                      <>
                        {search.sort === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={140}>
                <div onClick={() => handleOrderBy(`create`)}>
                  <u>
                    วันที่สั่งซื้อ{" "}
                    {search.sortBy === `create` && (
                      <>
                        {search.sort === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={140}>
                <div onClick={() => handleOrderBy(`payment`)}>
                  <u>
                    วันที่ชำระเงิน{" "}
                    {search.sortBy === `payment` && (
                      <>
                        {search.sort === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={140}>
                <div onClick={() => handleOrderBy(`expired`)}>
                  <u>
                    หมดเวลาชำระเงิน{" "}
                    {search.sortBy === `expired` && (
                      <>
                        {search.sort === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={330}></th>
            </tr>
          </thead>
          <tbody>
            {selector?.orders?.data?.length > 0 ? (
              selector?.orders?.data.map((v, i) => (
                <React.Fragment key={`row-${i}`}>
                  {v.items.map((item, index) => (
                    <tr key={`item-${index}`}>
                      <td
                        className="text-center align-middle"
                        headers={`ลำดับที่`}
                      >
                        {index == 0
                          ? rowRuning(i, search.page, search.pageSize)
                          : ``}
                      </td>
                      <td className="text-center" headers={`คำสั่งซื้อ`}>
                        {index == 0 ? (
                          <>
                            {v?.code}
                            {index == 0 && v.status !== `success` && (
                              <>
                                {v?.status === `cancel` ? (
                                  <div className={"mb-2"}>
                                    <span className="badge badge-danger">
                                      ยกเลิก
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    {moment(v.expiredAt ?? ``).unix() > 0 &&
                                      moment(v.expiredAt ?? ``).unix() <
                                        moment().unix() && (
                                        <div className={"mb-2"}>
                                          <span className="badge badge-warning">
                                            หมดเวลาชำระเงิน
                                          </span>
                                        </div>
                                      )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          ``
                        )}
                      </td>
                      <td className="text-left" headers={`รายการ`}>
                        {item?.name}
                      </td>
                      <td className="text-left" headers={`โดย`}>
                        <small>
                          <strong>สั่งซื้อโดย</strong> : {v?.userPurchase ?? ``}
                          <br />
                          <strong>ผู้มาใช้บริการ</strong> :{" "}
                          {item?.patientName ?? `-`}
                        </small>
                      </td>
                      <td className="text-center" headers={`การใช้งาน`}>
                        {v?.status === `success` && (
                          <>
                            {item?.status === 1 ? (
                              <span className="text-success">ใช้งานแล้ว</span>
                            ) : (
                              <span className="text-danger">
                                ยังไม่ได้ใช้งาน
                              </span>
                            )}
                          </>
                        )}
                      </td>
                      <td className="text-center" headers={`สถานะ`}>
                        {v?.status === `success` ? (
                          <span className="text-success">ชำระเงินแล้ว</span>
                        ) : v?.status === `cancel` ? (
                          <span className="text-danger">ยกเลิก</span>
                        ) : (
                          <span className="text-info">รอชำระเงิน</span>
                        )}
                      </td>
                      <td className="text-center" headers={`วันที่สั่งซื้อ`}>
                        <DateThai
                          date={v.createdAt}
                          showTime={true}
                          digit={true}
                        />
                      </td>
                      <td className="text-center" headers={`วันที่ชำระเงิน`}>
                        {typeof v?.payment?.transactionDateTime !==
                          `undefined` && (
                          <DateThai
                            dateInline={v.payment?.transactionDateTime}
                            digit={true}
                            showTime={true}
                          />
                        )}
                      </td>
                      <td className="text-center" headers={`หมดเวลาชำระเงิน`}>
                        {index == 0 && v.status !== `success` && (
                          <>
                            {moment(v.expiredAt ?? ``).unix() > 0 && (
                              <div className={"mb-2"}>
                                <DateThai
                                  date={v?.expiredAt ?? ``}
                                  digit={true}
                                  showTime={true}
                                />
                              </div>
                            )}
                            {v.status !== `cancel` && (
                              <button
                                className="btn btn-warning btn-sm"
                                onClick={() => handleExtendTime(v.key)}
                              >
                                <FiClock /> ขยายเวลา
                              </button>
                            )}
                          </>
                        )}
                      </td>
                      <td className="text-center">
                        {index == 0 && (
                          <>
                            <a
                              href="#"
                              className="btn btn-success  btn-sm mr-1 mb-1"
                              onClick={() =>
                                dispatch(
                                  setOpenModalContent({
                                    title: `รายละเอียด ${v.code}`,
                                    content: <OrderView order={v} />,
                                  }),
                                )
                              }
                            >
                              <FiEye /> รายละเอียด
                            </a>

                            {v?.status !== `cancel` && (
                              <a
                                href="#"
                                className="btn btn-primary btn-sm mr-1 mb-1"
                                onClick={() =>
                                  dispatch(
                                    setOpenModalContent({
                                      title: `รายละเอียด ${v.code}`,
                                      content: (
                                        <OrderEdit search={search} order={v} />
                                      ),
                                    }),
                                  )
                                }
                              >
                                <FiEdit /> แก้ไข
                              </a>
                            )}

                            {v?.status !== `cancel` &&
                              v?.status !== `success` && (
                                <button
                                  className="btn btn-danger  btn-sm mr-1 mb-1"
                                  onClick={() => handleCancelOrder(v.key)}
                                >
                                  <FiTrash2 /> ยกเลิก
                                </button>
                              )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  ไม่มีข้อมูล
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="paginate justify-content-between">
        <div className="total-items mr-3">
          ทั้งหมด {selector?.orders?.total} รายการ
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="หน้าถัดไป"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={selector?.orders?.totalPage}
          forcePage={search.page - 1}
          previousLabel="หน้าที่แล้ว"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default Order;
