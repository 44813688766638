import {imageSrc} from "../../common/functions/usage";
import React, {useState} from "react";
import {notificationErrorCustomMessage} from "../../common/config/message";

const ImageBoxUpload = ({label, note, name, initImage, uploadImage, handleChangeFile}) => {
    const hasImage = () => {

        if (typeof initImage !== `undefined` && initImage !== ``) {
            return true;
        }

        if (typeof uploadImage !== `undefined` && uploadImage !== ``) {
            return true;
        }


        return false
    }

    return (
        <>
            <div className="form-group">
                <label>{label}</label>
                <div className={`box-image-upload mb-1 ${hasImage() ? `has-image` : ``}`}>

                    {uploadImage ? <img src={uploadImage} className="imgNew" alt={name}/> : (
                        <>
                            {initImage &&
                                <img src={imageSrc(initImage)} className="imgOld" alt={name}/>}
                        </>
                    )}
                    <input type="file" name="image" onChange={e => handleChangeFile(e, name)}/>
                </div>
                <small>{note}</small>
            </div>
        </>
    )
}

export default ImageBoxUpload
