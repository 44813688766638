import update from 'immutability-helper'
import React, {useCallback, useEffect, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {setModal} from "../../../features/reducer/utility";
import {sortingPostAsync} from "../../../features/reducer/post/postThunk";
import {useDispatch} from "react-redux";
import ImageView from "../../utility/ImageView";
import {FiSave} from "react-icons/fi";
import {RiCloseFill} from "react-icons/ri";
import DoctorCard from "./DoctorCard";
import {sortingDoctorAsync} from "../../../features/reducer/doctor/doctorThunk";

const style = {
    width: `100%`,
}
const DoctorSorting = ({postType, posts, search}) => {
    const dispatch = useDispatch();


    const [cards, setCards] = useState([])

    useEffect(() => {
        setCards(posts)
    }, [posts])

    useEffect(() => {
        console.log(cards)
    }, [cards])

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setCards((prevCards) => update(prevCards, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, prevCards[dragIndex]],],
        }),)
    }, [])

    const renderCard = useCallback((card, index) => {
        return (<DoctorCard
            key={card.id}
            index={index}
            image={<ImageView name={card?.title} imageUrl={card?.image}/>}
            id={card.id}
            text={`${card.firstName} ${card.lastName}`}
            moveCard={moveCard}
        />)
    }, [])

    const handleSorting = () => {
        let sort = []
        cards.map((card, index) => {
            sort.push({
                id: card.id, sort: index
            })
        })
        dispatch(sortingDoctorAsync({
            sort: sort,
            search: search
        }))
        // console.log(sort)
    }


    return (<>
        <DndProvider backend={HTML5Backend}>
            <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
        </DndProvider>

        <div className="mt-4 d-flex align-items-center justify-content-end">
            <button className="btn btn-primary mr-2" onClick={e => handleSorting(e)}>
                <FiSave/> บันทึก
            </button>
            <button type="button" className="btn btn-danger" onClick={() => dispatch(setModal(false))}>
                <RiCloseFill/> ปิดหน้าต่าง
            </button>
        </div>
    </>)
}

export default DoctorSorting
