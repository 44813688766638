import axios from "axios";

import localStorageService from "../../services/localStorageService";
import {notificationErrorMessage} from "./message";
import {setLoading, setModal, setProgressDialog} from "../../features/reducer/utility";
import {setDataParams, SetGateWay} from "../api/gateway";

let isRefreshing = false;
let failedQueue = [];

const localStorage = localStorageService.getService()

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


const httpClient = axios.create({
    baseURL: window?.appConfig?.API_ENDPOINT || process.env.REACT_APP_API_BASE,
});

httpClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = "application/json";
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
    httpResponse
);


const httpClientFormValue = axios.create({
    baseURL: window?.appConfig?.API_ENDPOINT || process.env.REACT_APP_API_BASE,
});

httpClientFormValue.interceptors.request.use(
    (config) => {
        const token = localStorage.getAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        config.headers["Content-Type"] = 'application/x-www-form-urlencoded';
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    function (response) {
        return response;
    },
    httpResponse
);

function httpResponse(error) {
    const originalRequest = error.config;

    if (!error.response) {
        console.log("Please check your internet connection.");
        // window.location.href = `/login`
        return Promise.reject(error);
    }

    // console.log(error)
    if (error.response?.status === 401 && originalRequest.url === `/user/refreshToken`) {
        window.location.href = "/login";
        return Promise.reject(error);
    }

    const accessToken = localStorage.getAccessToken();
    const refreshToken = localStorage.getRefreshToken();

    const model = {
        accessToken: accessToken,
        refreshToken: refreshToken,
    };
    // alert(originalRequest.url)

    if (error.response?.status === 401 && !originalRequest._retry && originalRequest.url !== `/user/login`) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject});
            }).then(() => {
                originalRequest.headers[
                    "Authorization"
                    ] = `Bearer ${model.accessToken}`;
                return axios(originalRequest);
            }).catch((err) => {
                return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
            axios.post(`${window?.appConfig?.API_ENDPOINT || process.env.REACT_APP_API_BASE}/user/refreshToken`, model, {
                headers: {Authorization: `Bearer ${model.accessToken}`},
            }).then(resp => {
                const token = {
                    accessToken: resp.data.access_token,
                    refreshToken: resp.data.refresh_token,
                }
                localStorage.setToken(token);
                httpClient.defaults.headers.common["Authorization"] = `Bearer ${token.accessToken}`;
                originalRequest.headers["Authorization"] = `Bearer ${token.accessToken}`;
                resolve(axios(originalRequest));
            }).catch((err) => {
                console.log("refresh err:", err);

                processQueue(err, null);

                localStorage.forgetToken();
                window.location.href = "/login";
                reject({status: error.response.status, error: error});
            }).finally(() => {
                isRefreshing = false;
            });
        });
    }

    return Promise.reject(error);
}

export default httpClient;
export const httpClientForm = httpClientFormValue

export const httpRequestFunc = async (baseThunk, callback, showError = true) => {
    // SetGateWay()
    baseThunk.dispatch(setProgressDialog(true))
    baseThunk.dispatch(setLoading({loading: 'LOADING'}))
    try {
        const response = await callback
        if (response?.status === 200) {
            setTimeout(() => {
                baseThunk.dispatch(setProgressDialog(false))
                baseThunk.dispatch(setLoading({loading: 'SUCCESS'}))
            }, 500)
            return response
        }
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
    } catch (e) {
        baseThunk.dispatch(setProgressDialog(false))
        baseThunk.dispatch(setLoading({loading: 'ERROR'}))
        const data = e?.response?.data
        if (e?.response.status == 403 && data?.message == 'TOKEN_MISSING') {
            return
        }
        if (showError) {
            notificationErrorMessage(e)
        }
        throw baseThunk.rejectWithValue(e)
    }
}

export const httpObjQueryString = (data) => {
    if (typeof data != `undefined`) {
        let dt = setDataParams(data)
        return Object.entries(dt).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&')
    }

    return ``
}
