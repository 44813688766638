// import httpClient, {httpObjQueryString} from "../config/httpClient";
import {checkSubDomain, setDataParams, SetGateWay} from "./gateway";
import httpClient, {httpObjQueryString} from "../config/httpClient";
import axios from "axios";

const {httpGateWay, httpGateWayForm} = SetGateWay()

export const loginUserApi = (username, password) => {
    return httpGateWay.post(`/user/login`, {
        username: username, password: password, prefix: checkSubDomain()
    })
}

export const fetchUsersApi = (filter) => {
    return httpGateWay.get(`/user/lists?${httpObjQueryString(filter)}`)
}

export const getUserApi = (id) => {
    return httpGateWay.get(`/user/${id}`)
}

export const newUserApi = (data) => {
    return httpGateWayForm.post(`/user`, data)
}

export const updateUserApi = (id, data) => {
    return httpGateWayForm.put(`/user/${id}`, data)
}

export const deleteUserApi = (id) => {
    return httpGateWay.delete(`/user/${id}`)
}

export const updateProfileApi = (data) => {
    return httpGateWayForm.put(`/user/me`, data)
}

export const getProfileApi = () => {
    return httpGateWay.get(`/user/me`)
}


export const checkDomainConfigApi = (domain) => {
    let endpoint = ``
    if (process.env.REACT_APP_ENV !== `local`) {
        endpoint = `/v1`
    }
    return axios.post(`${window?.appConfig?.API_ENDPOINT || process.env.REACT_APP_API_BASE}${endpoint}/domain/auth`, {domain: domain})
}
