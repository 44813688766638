import {createSlice} from "@reduxjs/toolkit";
import {getAsyncSetting} from "./settingThunk";

const initialState = {
    options: {},
}


const settingSlice = createSlice({
    name: `setting`,
    initialState: initialState,
    reducers: {
        setSelectedOrderItems: (state, {payload}) => {
            return {
                ...state,
                selectedItems: payload
            }
        },
    },
    extraReducers: {
        [getAsyncSetting.fulfilled]: (state, {payload}) => {
            return {
                ...state,
                options: payload
            }
        }
    },
})

export const getSettings = (state) => state.setting.options

export default settingSlice.reducer
