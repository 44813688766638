// import httpClient, {httpObjQueryString} from "../config/httpClient";
import {checkSubDomain, setDataParams, SetGateWay} from "./gateway";
import httpClient, {httpObjQueryString} from "../config/httpClient";
import axios from "axios";

const {httpGateWay, httpGateWayForm} = SetGateWay()


export const fetchDoctorsApi = (filter) => {
    return httpGateWay.get(`/doctor/lists?${httpObjQueryString(filter)}`)
}

export const getDoctorApi = (id) => {
    return httpGateWay.get(`/doctor/${id}?preview=true`)
}

export const getDoctorSpecialtiesApi = () => {
    return httpGateWay.get(`/doctor/specialties`)
}

export const getDoctorClinicApi = () => {
    return httpGateWay.get(`/doctor/clinics`)
}

export const newDoctorApi = (data) => {
    return httpGateWayForm.post(`/doctor`, data)
}

export const updateDoctorApi = (id, data) => {
    return httpGateWayForm.put(`/doctor/${id}`, data)
}

export const updateDoctorLangApi = (id, lang, data) => {
    return httpGateWayForm.put(`/doctor/language/${lang}/${id}`, data)
}

export const deleteDoctorApi = (id) => {
    return httpGateWay.delete(`/doctor/${id}`)
}



export const sortingDoctorApi = (data) => {
    return httpGateWay.put(`/doctor/sorting`, data)
}
