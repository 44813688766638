import {createSlice} from "@reduxjs/toolkit";
import {addAppointmentAsync, fetchAppointmentAsync, getAppointmentAsync} from "./appointmentThunk";


const appointmentSlice = createSlice({
    name: "appointment",
    initialState: {
        appoitment: null,
        appoitments: null,
        success: null,
    },
    reducers: {},
    extraReducers: {
        [fetchAppointmentAsync.fulfilled]: (state, {payload}) => {
            return {...state, appoitments: payload}
        },
        [getAppointmentAsync.fulfilled]: (state, {payload}) => {
            return {...state, appoitment: payload}
        },
        [addAppointmentAsync.fulfilled]: (state, {payload}) => {
            return {...state, success: payload}
        },
    },
})

export const getAddAppointmentSuccess = (state) => state.appointment.success
export const getAppoitments = (state) => state.appointment.appoitments

export default appointmentSlice.reducer
