import DatePicker from "react-datepicker";
import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {FiSave} from "react-icons/fi";
import {useDispatch} from "react-redux";
import {updateOrderItemAsync} from "../../../features/reducer/order/orderThunk";
import moment from "moment";

const OrderItem = ({item, order, search}) => {
    // Store
    const dispatch = useDispatch()

    // State
    const [data, setData] = useState({
        item: ``, order: ``, usedDate: new Date()
    })

    // useEffect
    useEffect(() => {
        const d = moment(item?.usedDate).format(`YYYY-MM-DD`)
        let dError = false
        if (d === `0001-01-01`) {
            dError = true
        }
        setData({
            ...data,
            item: item?.sku || ``,
            order: order?.key || ``,
            usedDate: (typeof item?.usedDate !== `undefined` && item?.usedDate !== `` && !dError) ? new Date(moment(item?.usedDate).format(`YYYY-MM-DD`)) : new Date(),
            status: item?.status ?? 0,
            billNo: item?.billNo ?? ``,
            cashierName: item?.cashierName ?? ``,
            HN: item?.HN ?? ``,
            patientName: item?.patientName ?? ``,
            memo: item?.memo ?? ``,
        })
    }, [item])


    // handle
    const handleInput = (e) => {
        const {name, value} = e.target

        let v = value
        if (name === `status` || name === `packageDiscount`) {
            v = parseInt(v * 1)
        }

        setData({...data, [name]: v})
    }

    const handleDate = (v, name) => {
        let f = {...data, [name]: v}
        setData(f)
    }

    const handleSubmit = () => {
        dispatch(updateOrderItemAsync({
            search: search,
            data: {
                ...data, usedDate: moment(data.usedDate).format(`YYYY-MM-DD`)
            }
        }))
    }

    return (<>
        <div className="row row-sm mb-3">
            <div className="col-lg-6">
                <div className="mb-3 form-group">
                    <label htmlFor="">วันที่ใช้งาน</label>
                    <DatePicker dateFormat="yyyy-MM-dd" placeholderText="วันที่ใช้งาน" selected={data?.usedDate} onChange={date => handleDate(date, 'usedDate')} className="form-control"/>
                </div>
            </div>
            <div className="col-lg-6">
                <div className="mb-3 form-group">
                    <label htmlFor="">สถานะ</label>
                    <select name="status" onChange={e => handleInput(e)} value={data?.status || 0} className="form-control">
                        <option value="0">ยังใช้งาน</option>
                        <option value="1">ใช้งานแล้ว</option>
                    </select>
                </div>
            </div>
        </div>

        <div className="row row-sm">
            <div className="col-lg-8">
                <div className="mb-3">
                    <TextField fullWidth label="Bill No" size={"small"} name="billNo" value={data?.billNo || ''} onChange={e => handleInput(e)} variant="outlined"/>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="mb-3">
                    <TextField fullWidth label="Cashier Name" size={"small"} name="cashierName" value={data?.cashierName || ''} onChange={e => handleInput(e)} variant="outlined"/>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="mb-3">
                    <TextField fullWidth label="HN" size={"small"} name="HN" value={data?.HN || ''} onChange={e => handleInput(e)} variant="outlined"/>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="mb-3">
                    <TextField fullWidth label="Patient Name" size={"small"} name="patientName" value={data?.patientName || ''} onChange={e => handleInput(e)} variant="outlined"/>
                </div>
            </div>
            <div className="col-lg-4">
                <div className="mb-3">
                    <TextField fullWidth label="Memo" size={"small"} name="memo" value={data?.memo || ''} onChange={e => handleInput(e)} variant="outlined"/>
                </div>
            </div>
        </div>
        <button className="btn btn-primary" onClick={() => handleSubmit()}><FiSave/> บันทึก</button>
    </>)
}

export default OrderItem
