import {Link} from "react-router-dom";
import {
    BiBuilding,
    BiCalendar,
    BiDonateHeart,
    BiHeart, BiImage,
    BiMessage,
    BiMessageDetail,
    BiMessageSquareDetail,
    BiPlusMedical,
    BiStar, BiSupport
} from "react-icons/bi";
import {FiImage, FiMenu, FiShoppingCart, FiUser, FiUsers} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {activeMenu, activeSubMenu, getClickMenu, setSiderbar} from "../../features/reducer/utility";
import {CgClose} from "react-icons/cg";
import React, {useEffect, useState} from "react";
import {getProfile} from "../../features/reducer/user/userSlice";
import PerfectScrollbar from 'react-perfect-scrollbar'
import {MdBiotech, MdEditNote, MdOutlineLibraryBooks, MdOutlineThumbUpOffAlt, MdRoom} from "react-icons/md";
import {imageSrc} from "../../common/functions/usage";
import {IoMdGift} from "react-icons/io";
import {VscLayersActive} from "react-icons/vsc";
import {FaHospital, FaUserNurse} from "react-icons/fa";
import {GiPostOffice} from "react-icons/gi";

const Sidebar = ({routes, open}) => {
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)

    const isActiveMenu = useSelector(activeMenu)
    const isActiveSubMenu = useSelector(activeSubMenu)
    const clickMenu = useSelector(getClickMenu)
    const [dropdown, setDropdown] = useState()


    const handleActiveMenu = name => {
        return isActiveMenu === name ? `active` : ``
    }
    // const handleActiveSubMenu = name => {
    //     return isActiveSubMenu === name ? `active` : ``
    // }

    useEffect(() => {
        handleRemoveClassActive()
    }, [clickMenu]);

    const handleRemoveClassActive = () => {
        if (typeof dropdown !== `undefined` && dropdown !== `` && dropdown !== null) {
            dropdown.parentElement.classList.remove('active')
        }
    }

    const handleOpenDropdown = e => {
        if (e.target !== dropdown)
            handleRemoveClassActive()

        e.target.parentElement.classList.toggle('active');

        setDropdown(e.target)
    }

    const handleOpenSideMenu = () => {
        dispatch(setSiderbar(open ? false : true))
    }

    return (
        <aside className={`sidebar ${open ? `active` : ``}`}>
            <div className="logo my-4">
                <div>
                    <img src="/assets/images/logo.svg" alt=""/>
                </div>
                <div>
                    <div className="menu-bars mr-2" onClick={handleOpenSideMenu}>
                        <FiMenu/>
                    </div>
                </div>
            </div>
            <div className="mobile-close-memu" onClick={() => handleOpenSideMenu()}>
                <CgClose/>
            </div>
            {/*<div className="company-name text-white text-center mb-4">ระบบจัดการออร์เดอร์สินค้า</div>*/}
            <PerfectScrollbar>
                <div className="mb-4 text-center">
                    <div className="text-center mb-2">
                        <div className="img-profile mx-auto" style={{width: `96px`}}>
                            <div className="img-card-placeholder img-circle img-card-s1">
                                <div className="image" style={{backgroundImage: `url(${imageSrc(profile?.image ?? `/assets/images/user.png`)})`}}></div>
                            </div>
                            {/*<img src={} width={40} alt="user"/>*/}
                        </div>
                        {/*<img src="/assets/images/user.png" width={96} alt="user"/>*/}
                    </div>
                    <div className="text-white h6 mb-0">{profile?.name}</div>
                    <div className="text-muted"><small>{profile?.email ?? 'ไม่ระบุ'}</small></div>
                </div>
                <ul className="pb-5">

                    {/*<li className="head-line mt-3">บทความ</li>*/}
                    {/*<li className={handleActiveMenu(`dashboard`)}><Link to="/dashboard"><GoDashboard/>*/}
                    {/*    <span>ภาพรวม</span></Link>*/}
                    {/*</li>*/}

                    <li className="head-line mt-3">คำสั่งซื้อ</li>
                    <li className={handleActiveMenu(`order`)}>
                        <Link to="/order"><FiShoppingCart/>
                            <span>รายการคำสั่งซื้อ</span>
                        </Link>
                    </li>

                    <li className="head-line mt-3">ติดต่อเรา</li>
                    <li className={handleActiveMenu(`appointment`)}>
                        <Link to="/appointment"><BiCalendar/>
                            <span>ตารางการนัดหมาย</span>
                        </Link>
                    </li>
                    <li className={handleActiveMenu(`contact`)}>
                        <Link to="/contact"><BiMessage/>
                            <span>ข้อมูลการติดต่อ</span>
                        </Link>
                    </li>
                    <li className={handleActiveMenu(`appeal`)}>
                        <Link to="/appeal"><BiMessageDetail/>
                            <span>ข้อมูลการร้องเรียน</span>
                        </Link>
                    </li>

                    {/*<li className="head-line mt-3">บทความ</li>*/}


                    <li className="head-line mt-3">ภายในองค์กร</li>
                    <li className={handleActiveMenu(`post-news`)}>
                        <Link to="/news"><BiMessageSquareDetail/>
                            <span>ข่าวสารและกิจกรรม</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-activity`)}>
                        <Link to="/activity"><BiStar/>
                            <span>กิจกรรมเพื่อสังคม</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-medical`)}>
                        <Link to="/medical"><BiPlusMedical/>
                            <span>การแพทย์</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-nurse`)}>
                        <Link to="/nurse"><FaHospital/>
                            <span>พยาบาล</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-office`)}>
                        <Link to="/office"><BiBuilding/>
                            <span>สำนักงานบริหาร</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-medical-support`)}>
                        <Link to="/medical-support"><BiSupport/>
                            <span>สนับสนุนทางการแพทย์</span></Link>
                    </li>

                    <li className="head-line mt-3">บริการของเรา</li>
                    <li className={handleActiveMenu(`post-clinic`)}>
                        <Link to="/clinic"><BiDonateHeart/>
                            <span>ศูนย์รักษาโรค</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-technology`)}>
                        <Link to="/technology"><MdBiotech/>
                            <span>เทคโนโลยีของเรา</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-packages`)}>
                        <Link to="/packages"><IoMdGift/>
                            <span>แพ็คเกจและโปรโมชั่น</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-membercard`)}>
                        <Link to="/membercard"><FiUser/>
                            <span>บัตรสมาชิก</span></Link>
                    </li>

                    <li className="head-line mt-3">ข้อมูลเพื่อขอรับบริการ</li>
                    <li className={handleActiveMenu(`post-room`)}>
                        <Link to="/room"><MdRoom/>
                            <span>ห้องพักผู้ป่วย</span></Link>
                    </li>

                    <li className="head-line mt-3">ข้อมูลสุขภาพ</li>
                    <li className={handleActiveMenu(`post-healthy`)}>
                        <Link to="/healthy"><BiHeart/>
                            <span>บทความสุขภาพ</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-impression`)}>
                        <Link to="/impression"><MdOutlineThumbUpOffAlt/>
                            <span>ความประทับใจ</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-research`)}>
                        <Link to="/research"><MdOutlineLibraryBooks/>
                            <span>งานวิจัย</span></Link>
                    </li>

                    <li className={handleActiveMenu(`post-health`)}>
                        <Link to="/health"><MdEditNote/>
                            <span>บทความที่เกี่ยวข้อง & CV Doctor</span></Link>
                    </li>

                    <li className="head-line mt-3">ครอบครัว RJR</li>
                    <li className={handleActiveMenu(`post-rjractivity`)}>
                        <Link to="/rjractivity"><VscLayersActive/>
                            <span>กิจกรรม</span></Link>
                    </li>

                    <li className="head-line mt-3">ข้อมูลผู้บริหารและแพทย์</li>
                    <li className={handleActiveMenu(`doctor-director`)}>
                        <Link to="/personal/director"><FiUsers/>
                            <span>ข้อมูลคณะกรรมการบริหาร</span></Link>
                    </li>
                    <li className={handleActiveMenu(`doctor-board`)}>
                        <Link to="/personal/board"><FiUsers/>
                            <span>ข้อมูลคณะกรรมการบริษัท</span></Link>
                    </li>
                    <li className={handleActiveMenu(`doctor-manager`)}>
                        <Link to="/personal/manager"><FiUsers/>
                            <span>ผู้บริหารโรงพยาบาล</span></Link>
                    </li>
                    <li className={handleActiveMenu(`doctor-doctor`)}>
                        <Link to="/personal/doctor"><FiUsers/>
                            <span>ข้อมูลแพทย์</span></Link>
                    </li>

                    <li className="head-line mt-3">ผู้ใช้งาน</li>
                    <li className={handleActiveMenu(`user-admin-emp`)}>
                        <Link to="/user"><FiUser/>
                            <span>ข้อมูลผู้ใช้งาน</span></Link>
                    </li>
                    <li className={handleActiveMenu(`user-member`)}>
                        <Link to="/member"><FiUsers/>
                            <span>สมาชิก</span></Link>
                    </li>

                    <li className="head-line mt-3">ตั้งค่า</li>
                    <li className={handleActiveMenu(`post-slider`)}>
                        <Link to="/slider"><FiImage/>
                            <span>ภาพไสลด์</span></Link>
                    </li>
                    <li className={handleActiveMenu(`post-popup`)}>
                        <Link to="/popup"><BiImage/>
                            <span>Popup วันสำคัญ</span></Link>
                    </li>


                </ul>
            </PerfectScrollbar>
        </aside>
    )
}

export default Sidebar
