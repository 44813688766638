import React, { useEffect, useState } from "react";
import {
  FiArrowDown,
  FiArrowUp,
  FiEdit,
  FiPlusCircle,
  FiSearch,
  FiTrash2,
  FiUsers,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsyncDoctor,
  fetchAsyncDoctors,
  getAsyncDoctorClinics,
  getAsyncDoctorSpecialties,
} from "../../../features/reducer/doctor/doctorThunk";
import {
  getClinics,
  getDoctors,
  getDoctorType,
  getSpecialties,
} from "../../../features/reducer/doctor/doctorSlice";
import ReactPaginate from "react-paginate";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { rowRuning } from "../../../common/functions/usage";
import moment from "moment";
import {
  setActiveMenu,
  setOpenModalContent,
} from "../../../features/reducer/utility";
import ImageView from "../../utility/ImageView";
import { Link, useParams } from "react-router-dom";
import { FaSort } from "react-icons/fa";
import DoctorSorting from "./DoctorSorting";
import DatePicker from "react-datepicker";
import { fetchPostAsync } from "../../../features/reducer/post/postThunk";

let initState = {
  name: ``,
  page: 1,
  pageSize: 15,
  role: ``,
  status: `all`,
  sort: `sq`,
  startDate: "",
  endDate: "",
  sorting: `desc`,
  sortingBy: ``,
};

function Doctor({ role }) {
  const specialties = useSelector(getSpecialties);
  const clinics = useSelector(getClinics);
  const doctorType = useSelector(getDoctorType);

  /*Params*/
  const { type } = useParams();

  // let navigate = useNavigate();
  const dispatch = useDispatch();
  const doctors = useSelector(getDoctors);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [filter, setFilter] = useState(initState);
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState("");
  const [allowUnlimitViewm, setAllowUnlimitView] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let search = {
      filter: {
        ...filter,
        name: "",
        sorting: `desc`,
        sortingBy: ``,
      },
    };

    const allowUnlimit = [`director`, `board`, `manager`];

    if (typeof type !== `undefined`) {
      const index = allowUnlimit.findIndex((v) => v === type);
      if (index !== -1) {
        search.filter.pageSize = 1000;
        search.filter.sort = `sq`;
        setAllowUnlimitView(true);
      } else {
        search.filter.sort = `sq`;
        setAllowUnlimitView(false);
      }

      if (type === `doctor`) {
        search.filter.type = ``;
      } else {
        search.filter.type = type;
      }
      setFilter(search.filter);
    }
    dispatch(
      setActiveMenu(`doctor${typeof type !== `undefined` ? `-${type}` : ``}`),
    );
    dispatch(fetchAsyncDoctors(search));
    dispatch(getAsyncDoctorSpecialties({}));
    dispatch(getAsyncDoctorClinics({}));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const d = doctorType[type] ?? ``;
    if (d !== ``) {
      setTitle(d);
      document.title = d;
    } else {
      window.location.href = `/`;
      document.title = `ข้อมูลแพทย์`;
    }
  }, [dispatch, type]);

  const handleSearch = (value) => {
    if (inputTimeout) clearTimeout(inputTimeout);
    const f = { ...filter, name: value, page: 1 };
    let search = { filter: f };
    setFilter(f);
    setInputTimeout(
      setTimeout(() => {
        dispatch(fetchAsyncDoctors(search));
      }, 1000),
    );
  };

  const handleDelete = () => {
    dispatch(deleteAsyncDoctor({ id }));
    setOpen(false);
  };
  const handleOpen = (e, id) => {
    e.preventDefault();
    setId(id);
    setOpen(true);
  };

  const handlePageClick = (event) => {
    const pa = event.selected + 1;
    const f = { ...filter, page: pa };
    let search = { filter: f };
    setFilter(f);
    setPage(pa);
    dispatch(fetchAsyncDoctors(search));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const BranchName = ({ code }) => {
    const [b, setB] = useState();
    useEffect(() => {
      if (specialties.length > 0) {
        setB(specialties.find((item) => item.code == code));
      }
    }, [specialties, code]);
    return <>{b?.name ?? ""}</>;
  };
  const BranchSubName = ({ code, subCode }) => {
    const [b, setB] = useState();

    useEffect(() => {
      if (specialties.length > 0) {
        let a = specialties.find((item) => item.code == code);
        // console.log(a)
        a = a?.sub.find((item) => item.code == subCode);
        setB(a);
      }
    }, [specialties, code, subCode]);
    return <>{b?.name ?? ""}</>;
  };

  const ClinicName = ({ code }) => {
    const [b, setB] = useState();
    useEffect(() => {
      if (clinics.length > 0) {
        setB(clinics.find((item) => item.code == code));
      }
    }, [clinics, code]);
    return <>{b?.name ?? ""}</>;
  };

  const handleDate = (v, name) => {
    let f = { ...filter, [name]: v };
    setFilter(f);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnSearch();
    }
  };

  const handleOnSearch = () => {
    let s = {
      ...filter,
      page: 1,
      startDate: filter.startDate
        ? moment(filter.startDate).format(`YYYY-MM-DD`)
        : "",
      endDate: filter.endDate
        ? moment(filter.endDate).format(`YYYY-MM-DD`)
        : "",
    };
    if (filter.name) {
      s.startDate = ``;
      s.endDate = ``;
    }
    setFilter({
      ...filter,
      page: 1,
    });
    dispatch(
      fetchAsyncDoctors({
        filter: s,
      }),
    );
  };
  const handleOrderBy = (sortBy) => {
    let sort = `desc`;
    if (filter.sorting === `desc`) sort = `asc`;

    setFilter({
      ...filter,
      sorting: sort,
      sortingBy: sortBy,
    });
    const s = {
      ...filter,
      sorting: sort,
      sortingBy: sortBy,
      startDateTime: filter.startDateTime
        ? moment(filter.startDateTime).format(`YYYY-MM-DD`)
        : "",
      endDateTime: filter.endDateTime
        ? moment(filter.endDateTime).format(`YYYY-MM-DD`)
        : "",
    };
    dispatch(
      fetchAsyncDoctors({
        filter: s,
      }),
    );
  };

  return (
    <>
      <div className="box box-table">
        <div className="box-header d-flex align-items-center justify-content-between lg">
          <div>
            <FiUsers /> ข้อมูล {title}
          </div>
          <div className={`add-new`}>
            {allowUnlimitViewm && (
              <>
                <button
                  className="btn btn-warning mr-2"
                  onClick={() =>
                    dispatch(
                      setOpenModalContent({
                        title: `จัดเรียงการแสดงผล`,
                        content: (
                          <DoctorSorting
                            search={filter}
                            posts={doctors?.data}
                            postType={type}
                          />
                        ),
                      }),
                    )
                  }
                >
                  <FaSort /> จัดเรียงการแสดงผล
                </button>
              </>
            )}

            {typeof type !== `undefined` ? (
              <Link to={`/personal/${type}/create`} className="btn btn-primary">
                <FiPlusCircle /> สร้างใหม่
              </Link>
            ) : (
              <Link to={`/doctor/create`} className="btn btn-primary">
                <FiPlusCircle /> สร้างใหม่
              </Link>
            )}
          </div>
        </div>
        <div className="row justify-content-between form-filter">
          <div className="col-lg-12 mb-3 mb-lg-0">
            <div className="d-flex flex-wrap align-items-center">
              <div className="form-group mb-2 mr-2">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={(e) => handleInput(e)}
                  onKeyDown={handleKeyDown}
                  placeholder="ค้นหา"
                  style={{ width: 150 }}
                />
              </div>
              <div className="form-group  mb-2 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่เริ่มต้น"
                  selected={filter?.startDate}
                  onChange={(date) => handleDate(date, "startDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                  style={{ width: 200 }}
                />
              </div>
              <div className="form-group  mb-2 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่สิ้นสุด"
                  selected={filter?.endDate}
                  onChange={(date) => handleDate(date, "endDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                  style={{ width: 200 }}
                />
              </div>
              <div className="form-group mb-2 mr-2">
                <select
                  style={{ width: 200 }}
                  className="form-control"
                  name="clinic"
                  onChange={(e) => handleInput(e)}
                >
                  <option value={0}>เลือกคลินิค</option>
                  {clinics.length > 0 &&
                    clinics.map((specialty) => (
                      <option key={specialty.code} value={specialty.code}>
                        {specialty.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group mb-2 mr-2">
                <select
                  className="form-control"
                  name="branchSpecialty"
                  onChange={(e) => handleInput(e)}
                  style={{ width: 200 }}
                >
                  <option value={0}>เลือก สาขาที่เชียวชาญ</option>
                  {specialties.length > 0 &&
                    specialties.map((specialty) => (
                      <option key={specialty.code} value={specialty.code}>
                        {specialty.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group  mb-2 mr-2">
                <select
                  className="form-control"
                  name="subBranchSpecialty"
                  onChange={(e) => handleInput(e)}
                >
                  <option value={0}>เลือก สาขาย่อยที่เชียวชาญ</option>
                  {specialties.length > 0 &&
                    specialties.map(
                      (specialty) =>
                        specialty.code == filter?.branchSpecialty &&
                        typeof specialty?.sub !== `undefined` &&
                        specialty.sub.length > 0 &&
                        specialty.sub.map((sub) => (
                          <option key={sub.code} value={sub.code}>
                            {sub.name}
                          </option>
                        )),
                    )}
                </select>
              </div>
              <div className="form-group mb-2  mr-2">
                <select
                  name="status"
                  onChange={(e) => handleInput(e)}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                >
                  <option value="">สถานะทั้งหมด</option>
                  <option value="published">เผยแพร่</option>
                  <option value="draft">ฉบับร่าง</option>
                </select>
              </div>
              <div className="form-group mb-2 mr-2">
                <button
                  onClick={() => handleOnSearch()}
                  className="btn btn-success"
                >
                  <FiSearch /> ค้นหา
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive bg-white mb-4">
        <table className="table table-striped table-sm mb-0">
          <thead>
            <tr>
              <th className="text-center" width={60}>
                ลำดับที่
              </th>
              <th className="text-center" width={50}></th>
              <th className="text-left" style={{ minWidth: "150px" }}>
                <div onClick={() => handleOrderBy(`name`)}>
                  <u>
                    ชื่อ-นามสกุล{" "}
                    {filter.sortingBy === `name` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              {type === `doctor` ? (
                <>
                  <th className="text-left" width={250}>
                    สาขาที่เชียวชาญ
                  </th>
                  <th className="text-left" width={250}>
                    สาขาย่อยที่เชียวชาญ
                  </th>
                  <th className="text-center" width={150}>
                    คลินิก
                  </th>
                </>
              ) : (
                <>
                  <th className="text-left" width={250}>
                    โรงพยาบาล
                  </th>
                </>
              )}

              <th className="text-center" width={150}>
                สถานะ
              </th>
              <th className="text-center" width={150}>
                <div onClick={() => handleOrderBy(`create`)}>
                  <u>
                    วันที่เพิ่ม{" "}
                    {filter.sortingBy === `create` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>

              <th className="text-center" width={100}></th>
            </tr>
          </thead>
          <tbody>
            {doctors?.data?.length > 0 ? (
              doctors.data.map((v, i) => (
                <tr key={i}>
                  <td className="text-center align-middle" headers={`ลำดับที่`}>
                    {rowRuning(i, page, filter.pageSize)}
                  </td>
                  <td className="text-center align-middle" headers={`รูปภาพ`}>
                    <ImageView
                      name={`${v?.firstName} ${v?.lastName}`}
                      imageUrl={v?.image}
                    />
                  </td>
                  <td
                    className="text-left align-middle"
                    headers={`ชื่อ-นามสกุล`}
                  >
                    <div
                      className={`h6 mb-0`}
                    >{`${v?.prefix} ${v?.firstName} ${v?.lastName}`}</div>
                    <span className="text-muted">
                      {" "}
                      ลำดับการแสดงผล {v?.sort || `-`}
                    </span>
                  </td>
                  {type === `doctor` ? (
                    <>
                      <td
                        className="text-left align-middle"
                        headers={`สาขาที่เชียวชาญ`}
                      >
                        <BranchName code={v.branchSpecialty} />
                      </td>
                      <td
                        className="text-left align-middle"
                        headers={`สาขาย่อยที่เชียวชาญ`}
                      >
                        <BranchSubName
                          code={v.branchSpecialty}
                          subCode={v.subBranchSpecialty}
                        />
                      </td>
                      <td
                        className="text-center align-middle"
                        headers={`คลินิก`}
                      >
                        <ClinicName code={v.clinic} />
                      </td>
                    </>
                  ) : (
                    <>
                      <td
                        className="text-left align-middle"
                        headers={`โรงพยาบาล`}
                      >
                        {v.treatmentCenter}
                      </td>
                    </>
                  )}
                  <td className="text-center align-middle" headers={`สถานะ`}>
                    {v.status === `published` ? (
                      <span className="text-success">เผยแพร่</span>
                    ) : (
                      <span className="text-danger">แบบร่าง</span>
                    )}
                  </td>
                  <td
                    className="text-center align-middle"
                    headers={`วันที่เพิ่ม`}
                  >
                    <div>{moment(v.createdAt).format("YYYY/MM/DD HH:mm")}</div>
                  </td>

                  <td className="text-center align-middle" headers={`แก้ไข`}>
                    {typeof type !== `undefined` ? (
                      <a
                        href={`/personal/${type}/${v.code}/edit`}
                        className="t-icon mr-1"
                        title={`แก้ไข`}
                      >
                        <FiEdit />
                      </a>
                    ) : (
                      <a
                        href={`/doctor/${v.code}/edit`}
                        className="t-icon mr-1"
                        title={`แก้ไข`}
                      >
                        <FiEdit />
                      </a>
                    )}

                    <a
                      href="#"
                      onClick={(e) => handleOpen(e, v.id)}
                      className="t-icon"
                    >
                      <FiTrash2 />
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  <div className="h5 mb-0">ไม่พบข้อมูล</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="paginate justify-content-between">
        <div className="total-items mr-3">ทั้งหมด {doctors?.total} รายการ</div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="หน้าถัดไป"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={doctors?.totalPage}
          previousLabel="หน้าที่แล้ว"
          forcePage={filter.page - 1}
          renderOnZeroPageCount={null}
        />
      </div>

      <Dialog
        open={open}
        onClick={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการลบข้อมูล</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} className="text-success">
            ยืนยัน
          </Button>
          <Button onClick={() => setOpen(false)} className="text-danger">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Doctor;
