import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu } from "../../../features/reducer/utility";
import {
  changeStatusAppointmentAsync,
  fetchAppointmentAsync,
} from "../../../features/reducer/appointment/appointmentThunk";
import { BiCalendar, BiCheck } from "react-icons/bi";
import { getAppoitments } from "../../../features/reducer/appointment/appointmentSlice";
import { imageSrc, rowRuning } from "../../../common/functions/usage";
import moment from "moment";
import { IoClose } from "react-icons/io5";
import { getSpecialties } from "../../../features/reducer/doctor/doctorSlice";
import DatePicker from "react-datepicker";
import { FiArrowDown, FiArrowUp, FiSearch } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { fetchAsyncUsers } from "../../../features/reducer/user/userThunk";

const Appointment = () => {
  /*Store*/
  const dispatch = useDispatch();
  const appointments = useSelector(getAppoitments);
  const specialties = useSelector(getSpecialties);

  /*State*/
  const [search, setSearch] = useState({
    page: 1,
    pageSize: 15,
    startDate: "",
    endDate: "",
    sort: `desc`,
    sortBy: ``,
    sorting: `desc`,
    sortingBy: ``,
  });

  /*UseEffect*/
  useEffect(() => {
    document.title = `ตารางการนัดหมาย`;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(setActiveMenu(`appointment`));
    dispatch(fetchAppointmentAsync(search));
  }, [dispatch]);

  /*Component*/
  const BranchName = ({ code }) => {
    const [b, setB] = useState();
    useEffect(() => {
      if (specialties.length > 0) {
        setB(specialties.find((item) => item.code == code));
      }
    }, [specialties, code]);
    return <>{b?.name ?? " : ไม่ระบุ"}</>;
  };

  /*Handle*/
  const handleChangeStatus = (id, status) => {
    let confirm = window.confirm(`ยืนยัน`);
    if (confirm) {
      dispatch(
        changeStatusAppointmentAsync({
          filter: search,
          id: id,
          data: {
            status: status,
          },
        }),
      );
    }
  };

  const handleDate = (v, name) => {
    let f = { ...search, [name]: v };
    setSearch(f);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setSearch({
      ...search,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    let s = {
      ...search,
      page: 1,
      startDate: search.startDate
        ? moment(search.startDate).format(`YYYY-MM-DD`)
        : "",
      endDate: search.endDate
        ? moment(search.endDate).format(`YYYY-MM-DD`)
        : "",
    };
    if (search.search || search.doctorName) {
      s.startDate = ``;
      s.endDate = ``;
    }
    setSearch({
      ...search,
      page: 1,
    });
    dispatch(fetchAppointmentAsync(s));
  };

  const handleOrderBy = (sortBy) => {
    let sort = `desc`;
    if (search.sorting === `desc`) sort = `asc`;

    setSearch({
      ...search,
      sorting: sort,
      sortingBy: sortBy,
    });
    const s = {
      ...search,
      sorting: sort,
      sortingBy: sortBy,
      startDateTime: search.startDateTime
        ? moment(search.startDateTime).format(`YYYY-MM-DD`)
        : "",
      endDateTime: search.endDateTime
        ? moment(search.endDateTime).format(`YYYY-MM-DD`)
        : "",
    };
    dispatch(fetchAppointmentAsync(s));
  };

  const handlePageClick = (event) => {
    const pa = event.selected + 1;
    const f = { ...search, page: pa };
    setSearch({
      ...search,
      page: pa,
    })
    dispatch(fetchAppointmentAsync(f));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="box box-table">
        <div className="box-header lg">
          <BiCalendar /> ตารางการนัดหมาย
        </div>
        <div className="row justify-content-between mb-3 mb-lg-0 form-filter">
          <div className="col-lg-12 mb-3 mb-lg-0">
            <div className="d-flex flex-wrap align-items-center">
              <div className="form-group mb-lg-0 mr-2">
                <input
                  type="text"
                  className="form-control"
                  name="search"
                  onChange={(e) => handleInput(e)}
                  onKeyDown={handleKeyDown}
                  placeholder="ชื่อผู้นัดหมาย เบอร์โทรศัพท์ หรือ อีเมล์"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <input
                  type="text"
                  className="form-control"
                  name="doctorName"
                  onKeyDown={handleKeyDown}
                  onChange={(e) => handleInput(e)}
                  placeholder="ค้นหาจากชื่อแพทย์"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่เริ่มต้น"
                  selected={search?.startDate}
                  onChange={(date) => handleDate(date, "startDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่สิ้นสุด"
                  selected={search?.endDate}
                  onChange={(date) => handleDate(date, "endDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <select
                  name="status"
                  onChange={(e) => handleInput(e)}
                  className="form-control"
                >
                  <option value="">สถานะทั้งหมด</option>
                  <option value="pending">รอดำเนินการ</option>
                  <option value="success">ยืนยัน</option>
                  <option value="cancel">ยกเลิก</option>
                </select>
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <button
                  onClick={() => handleSearch()}
                  className="btn btn-success"
                >
                  <FiSearch /> ค้นหา
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive bg-white mb-4">
        <table className="table table-striped mb-0">
          <thead>
            <tr>
              <th className="text-center" width={80}>
                ลำดับที่
              </th>

              <th className="text-left" width={200}>
                <div onClick={() => handleOrderBy(`name`)}>
                  <u>
                    ผู้นัดหมาย{" "}
                    {search.sortingBy === `name` && (
                      <>
                        {search.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-left" width={200}>
                <div onClick={() => handleOrderBy(`doctor`)}>
                  <u>
                    แพทย์{" "}
                    {search.sortingBy === `doctor` && (
                      <>
                        {search.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-left" width={300}>
                ข้อมูลการติดต่อ
              </th>
              <th className="text-left" width={300}>
                ปัญหาสุขภาพ
              </th>
              <th className="text-left" width={300}>
                อาการเบื้องต้น
              </th>
              <th className="text-center" width={150}>
                <div onClick={() => handleOrderBy(`create`)}>
                  <u>
                    วันที่เพิ่ม{" "}
                    {search.sortingBy === `create` && (
                      <>
                        {search.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {appointments?.data?.length > 0 ? (
              appointments.data.map((app, i) => (
                <tr>
                  <td className="text-center" headers={`ลำดับ`}>
                    {rowRuning(i, search.page, search.pageSize)}
                  </td>

                  <td headers={`ผู้นัดหมาย`}>
                    <div className="d-inline d-lg-block">
                      <h5 className="mb-1" style={{ fontSize: `16px` }}>
                        {app?.fullName || (
                          <i
                            className="text-muted font-weight-normal"
                            style={{ fontSize: `14px` }}
                          >
                            ไม่ระบุ
                          </i>
                        )}
                      </h5>
                      <div>
                        <strong>สัญชาติ</strong> : {app?.nationality}
                      </div>
                      <div>
                        {app?.status === `pending` && (
                          <span className="badge badge-warning">
                            รอดำเนินการ
                          </span>
                        )}
                        {app?.status === `success` && (
                          <span className="badge badge-success">ยืนยัน</span>
                        )}
                        {app?.status === `cancel` && (
                          <span className="badge badge-danger">ยกเลิก</span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td headers={`แพทย์`}>
                    <div className="d-inline d-lg-block">
                      {(app?.selector === `0` || !app?.selector) && (
                        <>{app?.doctorName}</>
                      )}
                      {app?.selector === `1` && (
                        <>
                          <i className="text-info">แนะนำแพทย์โดยโรงพยาบาล</i>
                        </>
                      )}
                      <div className="text-muted mt-0">
                        <small>
                          สาขาที่เชียวชาญ{" "}
                          {app?.specialty !== `` && app?.specialty ? (
                            <BranchName code={app?.specialty} />
                          ) : (
                            ` : ไม่ระบุ`
                          )}
                        </small>
                      </div>
                    </div>
                  </td>
                  <td headers={`ข้อมูลการติดต่อ`}>
                    <div className="d-inline d-lg-block">
                      <div>
                        <strong>หมายเลขโทรศัพท์</strong> : {app?.phone}
                      </div>
                      <div>
                        <strong>อีเมล์</strong> : {app?.email || "-"}
                      </div>
                      <div>
                        <strong>วันที่นัดหมาย</strong> :
                        {(app?.type === `1` || app?.type === ``) && (
                          <span>
                            {moment(app?.date).format(`YYYY-MM-DD`)}
                            <span>
                              <small className="font-weight-normal">
                                {" "}
                                (วันที่สำรอง{" "}
                                {moment(app?.dateBackup).format(`YYYY-MM-DD`)})
                              </small>
                            </span>
                          </span>
                        )}
                        {app?.type === `0` && (
                          <i className="text-success">
                            วันที่เร็วที่สุดที่จะนัดหมาย
                          </i>
                        )}
                      </div>
                      {app?.file !== `` && (
                        <div>
                          <strong>ไฟล์แนบ</strong> :{" "}
                          <a
                            href={imageSrc(app?.file)}
                            className="text-primary"
                            target="_blank"
                          >
                            <u>ดาวน์โหลด</u>
                          </a>
                        </div>
                      )}
                    </div>
                  </td>
                  <td headers={`ปัญหาสุขภาพ`}>
                    <div
                      className="d-inline d-lg-block"
                      style={{ wordBreak: `break-all` }}
                    >
                      {app?.note}
                    </div>
                  </td>
                  <td headers={`อาการเบื้องต้น`}>
                    <div
                      className="d-inline d-lg-block"
                      style={{ wordBreak: `break-all` }}
                    >
                      {app?.healthyNote}
                    </div>
                  </td>
                  <td
                    className="text-center align-middle"
                    headers={`วันที่เพิ่ม`}
                  >
                    <div>
                      {moment(app.createdAt).format("YYYY/MM/DD HH:mm")}
                    </div>
                  </td>
                  <td className="text-center">
                    {app?.status === `pending` ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <button
                          className="btn btn-success  btn-sm m-1"
                          onClick={() => handleChangeStatus(app?.id, `success`)}
                        >
                          <BiCheck /> ยืนยัน
                        </button>
                        <button
                          className="btn btn-danger  btn-sm m-1"
                          onClick={() => handleChangeStatus(app?.id, `cancel`)}
                        >
                          <IoClose /> ยกเลิก
                        </button>
                      </div>
                    ) : (
                      <>
                        {app?.confirmedAt !== null &&
                          app?.confirmedAt !== `` && (
                            <>
                              <div>
                                {" "}
                                อัพเดตวันที่{" "}
                                {moment(app?.confirmedAt).format(
                                  `YYYY-MM-DD HH:mm`,
                                )}
                              </div>
                              โดย {app?.confirmByUsername ?? "-"}
                            </>
                          )}
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>

      <div className="paginate justify-content-between">
        <div className="total-items mr-3">ทั้งหมด {appointments?.total} รายการ</div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="หน้าถัดไป"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={appointments?.totalPage}
          previousLabel="หน้าที่แล้ว"
          forcePage={search.page - 1}
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
};

export default Appointment;
