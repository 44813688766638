import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProfile, isAuthentications, setisAuthenticated, userConfig} from "../../features/reducer/user/userSlice";
import localStorageService from "../../services/localStorageService";
import {Outlet, useNavigate} from "react-router-dom";
import Sidebar from "./Sidebar";
import {getAsyncProfile} from "../../features/reducer/user/userThunk";
import {
    anouceAync,
    myModal,
    progressDialog,
    redirect,
    setRedirect,
    setSiderbar,
    sideBarOpen
} from "../../features/reducer/utility";
import MyModal from "../utility/MyModal";
import {Spinner} from "react-bootstrap";
import {decryptJson} from "../../common/functions/encrypt";
import Navbar from "./Navbar";

function Admin() {
    let navigate = useNavigate();
    const localStorage = localStorageService.getService()
    const dispatch = useDispatch()
    const isauthenticated = useSelector(isAuthentications)
    const myModalApp = useSelector(myModal)
    const profile = useSelector(getProfile)
    const isRedirect = useSelector(redirect)
    const progressBar = useSelector(progressDialog)
    const open = useSelector(sideBarOpen)
    const uConfig = useSelector(userConfig)
    const [config, setConfig] = useState(uConfig)
    const size = useWindowSize();


    const [logined, setLogined] = useState(localStorage.isAuthentication())
    // const [open, setOpen] = useState(true)

    useEffect(() => {
        if (!isauthenticated)
            dispatch(setisAuthenticated(logined))
        if (profile == null)
            dispatch(getAsyncProfile())
        dispatch(anouceAync())

    }, [dispatch])



    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [dispatch, isRedirect])


    useEffect(() => {
        if (!logined)
            navigate('/login')


    }, []);
    useEffect(() => {
        dispatch(setSiderbar(size.width < 1199 ? false : true))
    }, [size.width])

    const handleOpenSideMenu = () => {
        dispatch(setSiderbar(open ? false : true))
    }


    return (
        <>
            <div className={`wrapper ${open ? `sidemenu-active` : ``}`}>
                <Sidebar handleOpenSideMenu={handleOpenSideMenu} open={open}/>
                <div className="wrapper-content">
                    <Navbar handleOpenSideMenu={handleOpenSideMenu}/>
                    <div className="wrapper-manage">
                        <Outlet/>
                    </div>
                </div>
                <MyModal title={myModalApp?.title} size={myModalApp?.size}>{myModalApp?.content}</MyModal>
            </div>


            {progressBar && (
                <div className="progress-loading">
                    <div className="progress-inner">
                        <Spinner animation="border" variant="primary"/>
                        <div className="ml-2">กำลังประมวลผล...</div>
                    </div>
                </div>
            )}
        </>
    );
}

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

export default Admin;
