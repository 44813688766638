import React, { useEffect, useState } from "react";
import {
  FiArrowDown,
  FiArrowUp,
  FiEdit,
  FiPlusCircle,
  FiSearch,
  FiTrash2,
  FiUsers,
} from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsyncUser,
  fetchAsyncUsers,
} from "../../../features/reducer/user/userThunk";
import { getProfile, getUsers } from "../../../features/reducer/user/userSlice";
import ReactPaginate from "react-paginate";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { rowRuning } from "../../../common/functions/usage";
import moment from "moment";
import {
  setActiveMenu,
  setOpenModalContent,
} from "../../../features/reducer/utility";
import UserCreate from "./UserCreate";
import UserType from "../../utility/UserType";
import ImageView from "../../utility/ImageView";
import DatePicker from "react-datepicker";
import ExcelExport from "../../../services/excelExport";
import { httpRequestFunc } from "../../../common/config/httpClient";
import { fetchUsersApi } from "../../../common/api/userApi";

let initState = {
  name: ``,
  page: 1,
  pageSize: 15,
  role: ``,
  sorting: `desc`,
  sortingBy: ``,
  startDate: new Date(moment().subtract(5, `years`).format(`YYYY-MM-DD`)),
  endDate: new Date(),
};

function User({ role }) {
  // let navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [filter, setFilter] = useState(initState);
  const [page, setPage] = useState(1);
  const [roleName, setRoleName] = useState("");
  const profile = useSelector(getProfile);

  useEffect(() => {
    document.title = `ข้อมูล ${role === `member` ? `สมาชิก` : `ผู้ใช้งาน`}`;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [role]);

  useEffect(() => {
    let search = {
      filter: {
        ...filter,
        name: "",
        sorting: `desc`,
        sortingBy: ``,
      },
    };
    if (typeof role !== `undefined`) {
      search = { filter: { ...filter, role: role } };
      dispatch(setActiveMenu(`user-${role}`));
      setRoleName(role);
      setFilter({
        ...filter,
        role: role,
      });
    } else {
      dispatch(setActiveMenu(`user-emp`));
      setRoleName("");
    }
    dispatch(fetchAsyncUsers(search));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [role]);

  const handleSearch = (value) => {
    if (inputTimeout) clearTimeout(inputTimeout);
    const f = { ...filter, name: value };
    let search = { filter: f };
    setFilter(f);
    setInputTimeout(
      setTimeout(() => {
        dispatch(fetchAsyncUsers(search));
      }, 1000),
    );
  };

  const handleDelete = () => {
    dispatch(deleteAsyncUser({ id }));
    setOpen(false);
  };
  const handleOpen = (e, id) => {
    e.preventDefault();
    setId(id);
    setOpen(true);
  };

  const handlePageClick = (event) => {
    const pa = event.selected + 1;
    const f = { ...filter, page: pa };
    let search = { filter: f };
    setFilter(f);
    setPage(pa);
    dispatch(fetchAsyncUsers(search));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDate = (v, name) => {
    let f = { ...filter, [name]: v };
    setFilter(f);
  };

  const handleInput = (e) => {
    const { value, name } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleOnSearch();
    }
  };

  const handleOnSearch = () => {
    let s = {
      ...filter,
      page: 1,
      startDate: moment(filter.startDate).format(`YYYY-MM-DD`),
      endDate: moment(filter.endDate).format(`YYYY-MM-DD`),
    };
    if (filter.name) {
      s.startDate = ``;
      s.endDate = ``;
    }
    setFilter({
      ...filter,
      page: 1,
    });
    dispatch(
      fetchAsyncUsers({
        filter: s,
      }),
    );
  };
  const handleOrderBy = (sortBy) => {
    let sort = `desc`;
    if (filter.sorting === `desc`) sort = `asc`;

    setFilter({
      ...filter,
      sorting: sort,
      sortingBy: sortBy,
    });
    const s = {
      ...filter,
      sorting: sort,
      sortingBy: sortBy,
      startDateTime: moment(filter.startDateTime).format(`YYYY-MM-DD`),
      endDateTime: moment(filter.endDateTime).format(`YYYY-MM-DD`),
    };
    dispatch(
      fetchAsyncUsers({
        filter: s,
      }),
    );
  };

  const handleLoadData = async (ExcelExport) => {
    try {
      let s = {
        ...filter,
        showAll: "1",
        page: 1,
        startDate: moment(filter.startDate).format(`YYYY-MM-DD`),
        endDate: moment(filter.endDate).format(`YYYY-MM-DD`),
      };
      const response = await fetchUsersApi(s);
      let excels = [];
      const users = response.data?.data;
      if (users.length > 0) {
        users.map((user, index) => {
          const row = {
            ลำดับที่: index + 1,
            ชื่อเข้าใช้งาน: user.username,
            "ชื่อ-นามสกุล": user.name,
            เบอร์โทรศัพท์: user.phone,
            อีเมล์: user.email,
            วันที่เพิ่ม: moment(user.createdAt).format("YYYY/MM/DD HH:mm"),
          };
          excels.push(row);
        });
      }
      ExcelExport(excels);
    } catch (e) {}
  };

  return (
    <>
      {/*<div className="title mb-3">*/}
      {/*    <h3 className="text-primary">{roleName !== `member` ? 'ข้อมูลพนักงาน' : 'ข้อมูลสมาชิก'}</h3>*/}
      {/*</div>*/}
      <div className="box box-table">
        <div className="box-header lg">
          <FiUsers />{" "}
          {roleName !== `member` ? "ข้อมูลผู้ใช้งาน" : "ข้อมูลสมาชิก"}
        </div>
        <div className="row justify-content-between form-filter">
          <div className="col-lg-8 mb-3 mb-lg-0">
            <div className="d-flex flex-wrap align-items-center">
              <div className="form-group mb-lg-0 mr-2">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  onChange={(e) => handleInput(e)}
                  onKeyDown={handleKeyDown}
                  placeholder="ค้นหา"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่เริ่มต้น"
                  selected={filter?.startDate}
                  onChange={(date) => handleDate(date, "startDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  placeholderText="วันที่สิ้นสุด"
                  selected={filter?.endDate}
                  onChange={(date) => handleDate(date, "endDate")}
                  onKeyDown={handleKeyDown}
                  className="form-control"
                />
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <button
                  onClick={() => handleOnSearch()}
                  className="btn btn-success"
                >
                  <FiSearch /> ค้นหา
                </button>
              </div>
              <div className="form-group mb-lg-0 mr-2">
                <ExcelExport
                  fileName={`export`}
                  handleLoadData={handleLoadData}
                />
              </div>
            </div>
          </div>
          {roleName !== `member` && (
            <div className="col-lg-4 text-lg-right">
              <button
                className="btn btn-primary"
                onClick={() =>
                  dispatch(
                    setOpenModalContent({
                      title: `เพิ่มข้อมูลใหม่`,
                      content: <UserCreate role={roleName} />,
                    }),
                  )
                }
              >
                สร้างพนักงานใหม่ <FiPlusCircle />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="table-responsive bg-white mb-4">
        <table className="table table-striped table-sm mb-0">
          <thead>
            <tr>
              <th className="text-center" width={60}>
                ลำดับที่
              </th>
              <th className="text-center" width={50}></th>
              <th className="text-left" width={120}>
                <div onClick={() => handleOrderBy(`username`)}>
                  <u>
                    ชื่อเข้าใช้งาน{" "}
                    {filter.sortingBy === `username` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-left" style={{ minWidth: "150px" }}>
                <div onClick={() => handleOrderBy(`name`)}>
                  <u>
                    ชื่อ-นามสกุล{" "}
                    {filter.sortingBy === `name` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={150}>
                <div onClick={() => handleOrderBy(`phone`)}>
                  <u>
                    เบอร์โทรศัพท์{" "}
                    {filter.sortingBy === `phone` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={150}>
                <div onClick={() => handleOrderBy(`email`)}>
                  <u>
                    อีเมล์{" "}
                    {filter.sortingBy === `email` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              <th className="text-center" width={150}>
                สิทธิ์การใช้งาน
              </th>
              <th className="text-center" width={150}>
                <div onClick={() => handleOrderBy(`create`)}>
                  <u>
                    วันที่เพิ่ม{" "}
                    {filter.sortingBy === `create` && (
                      <>
                        {filter.sorting === `desc` ? (
                          <FiArrowUp />
                        ) : (
                          <FiArrowDown />
                        )}
                      </>
                    )}
                  </u>
                </div>
              </th>
              {profile?.role === `admin` && (
                <th className="text-center" width={100}></th>
              )}
            </tr>
          </thead>
          <tbody>
            {users?.data?.length > 0 ? (
              users.data.map((v, i) => (
                <tr key={i}>
                  <td className="text-center align-middle" headers={`ลำดับที่`}>
                    {rowRuning(i, page, filter.pageSize)}
                  </td>
                  <td className="text-center align-middle" headers={`รูปภาพ`}>
                    <ImageView name={v?.username} imageUrl={v?.image} />
                  </td>
                  <td
                    className="text-left align-middle"
                    headers={`ชื่อเข้าใช้งาน`}
                  >
                    {v?.username}
                  </td>
                  <td className="text-left align-middle" headers={`ชื่อ`}>
                    {v.name}{" "}
                  </td>
                  <td className="text-center align-middle" headers={`โทรศัพท์`}>
                    {v?.phone ?? ``}
                  </td>
                  <td className="text-center align-middle" headers={`อีเมล์`}>
                    {v?.email ?? ``}
                  </td>
                  <td className="text-center align-middle" headers={`ระดับ`}>
                    <div>
                      <UserType type={v?.role} />
                    </div>
                  </td>
                  <td
                    className="text-center align-middle"
                    headers={`วันที่เพิ่ม`}
                  >
                    <div>{moment(v.createdAt).format("YYYY/MM/DD HH:mm")}</div>
                  </td>
                  {profile?.role === `admin` && (
                    <td className="text-center align-middle" headers={`แก้ไข`}>
                      {/*<Link to={`/user/${v.username}/edit`} className="t-icon mr-1">*/}
                      {/*    <FiEdit/>*/}
                      {/*</Link>*/}
                      <a
                        href="#"
                        className="pointer t-icon"
                        onClick={() =>
                          dispatch(
                            setOpenModalContent({
                              title: `แก้ไข ${v.username}`,
                              content: <UserCreate role={roleName} user={v} />,
                            }),
                          )
                        }
                      >
                        <FiEdit />
                      </a>
                      {profile?.id !== v.id && (
                        <a
                          href="#"
                          onClick={(e) => handleOpen(e, v.id)}
                          className="t-icon"
                        >
                          <FiTrash2 />
                        </a>
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={10} className="text-center">
                  <div className="h5 mb-0">ไม่พบข้อมูล</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="paginate justify-content-between">
        <div className="total-items mr-3">ทั้งหมด {users?.total} รายการ</div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="หน้าถัดไป"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={users?.totalPage}
          previousLabel="หน้าที่แล้ว"
          forcePage={filter.page - 1}
          renderOnZeroPageCount={null}
        />
      </div>

      <Dialog
        open={open}
        onClick={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ยืนยันการลบข้อมูล</DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete} className="text-success">
            ยืนยัน
          </Button>
          <Button onClick={() => setOpen(false)} className="text-danger">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default User;
