import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";

import userReducer from './reducer/user/userSlice'
import doctorReducer from './reducer/doctor/doctorSlice'
import categoryReducer from './reducer/category/categorySlice'
import postReducer from './reducer/post/postSlice'
import settingReducer from './reducer/setting/settingSlice'
import appointmentReducer from './reducer/appointment/appointmentSlice'
import contactReducer from './reducer/contact/contactSlice'
import orderRuducer from './reducer/order/orderSlice'
import utilityReducer from './reducer/utility'

const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
})
export const store = configureStore({
    reducer: {
        utility: utilityReducer,
        users: userReducer,
        doctors: doctorReducer,
        category: categoryReducer,
        post: postReducer,
        appointment: appointmentReducer,
        contact: contactReducer,
        setting: settingReducer,
        order: orderRuducer,
    }, middleware: customizedMiddleware,
})
