import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {
    addAppointmentApi,
    changeStatusAppointmentApi,
    fetchAppointmentApi,
    getAppointmentApi
} from "../../../common/api/appointmentApi";
import moment from "moment";
import {notificationErrorCustomMessage} from "../../../common/config/message";

export const fetchAppointmentAsync = createAsyncThunk(
    "doctor/fetchAppointmentAsync",
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchAppointmentApi(filter))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export const getAppointmentAsync = createAsyncThunk(
    "doctor/getAppointmentAsync",
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getAppointmentApi(id))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)
export const addAppointmentAsync = createAsyncThunk(
    "doctor/addAppointmentAsync",
    async ({data}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, addAppointmentApi(data))
            if (response.status === 200) {
                return true
            }
        } catch (e) {
            console.log(e)
        }
        return false
    }
)
export const changeStatusAppointmentAsync = createAsyncThunk(
    "doctor/changeAppointmentAsync",
    async ({id, filter, data}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, changeStatusAppointmentApi(id, data))
            if (response.status === 200) {
                notificationErrorCustomMessage(`เปลี่ยนสถานะสำเร็จ`, `success`)
                baseThunk.dispatch(fetchAppointmentAsync(filter))
            }
        } catch (e) {
            console.log(e)
        }
        return false
    }
)
