import DateThai from "../../utility/DateThai";
import CurrencyFormat from "react-currency-format";
import {TextField} from "@mui/material";
import React, {useState} from "react";
import DatePicker from "react-datepicker";
import OrderItem from "./OrderItem";
import {useDispatch} from "react-redux";
import {updateOrderItemAsync} from "../../../features/reducer/order/orderThunk";

const OrderEdit = ({order, search}) => {
    // Store
    const dispatch = useDispatch()

    // State
    const [data, setData] = useState()




    return (
        <div className="p-3">

            <div className="h4">รายการสั่งซิ้อ</div>
            {order?.items?.length > 0 ? order?.items?.map(item => (
                <div key={item.id}>
                    <div className="row align-items-center mb-0">
                        <div className="col-6">
                            <div className="h6 mb-0">{item?.name}</div>
                        </div>
                        <div className="col-6 text-right">
                            <div className="h4 mb-1" style={{lineHeight: 1}}>
                                <CurrencyFormat value={item?.amount} displayType={'text'} thousandSeparator={true}/> บาท
                            </div>

                            {item?.discount !== `` && item?.discount !== 0 &&
                                <div className="text-muted" style={{textDecoration: "line-through"}}>
                                    <CurrencyFormat value={item?.cost || 0} displayType={'text'} thousandSeparator={true}/> บาท
                                </div>
                            }

                            {/*<div className="text-muted" style={{textDecoration: "line-through"}}>3,000 บาท</div>*/}
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="text-primary">วันหมดอายุการใช้งาน:</div>
                        <DateThai date={item?.packageDate ?? ``}/>
                        {/*<DateThai date={item?.startDate}/> - <DateThai date={item?.endDate}/>*/}
                    </div>

                    <OrderItem search={search} item={item} order={order} />

                    <hr/>
                </div>
            )) : (<></>)}


        </div>
    )
}

export default OrderEdit
