import DateThai from "../../utility/DateThai";
import CurrencyFormat from "react-currency-format";
import {FiPrinter} from "react-icons/fi";
import {useState} from "react";
import moment from "moment";

const OrderView = ({order}) => {

    const [paymentChannel, setPaymentChannel] = useState("")

    const handleOnPrint = () => {
        window.print()
    }

    return (
        <div className="p-3 print-only">
            <div className="row mb-4 justify-content-between">
                <div className="col-md-6">
                    <div className="h4 mb-0">ข้อมูลการชำระเงิน</div>
                </div>
                <div className="col-md-3 text-md-right">
                    <button className="btn btn-sm btn-success no-print" onClick={() => handleOnPrint()}>
                        <FiPrinter/> พิมพ์รายละเอียด
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">Invoice No :</label>
                        <div className="font-weight-normal" style={{fontSize: `14px`}}>
                            {order?.code || ``}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">Transaction Ref :</label>
                        <div className="font-weight-normal">
                            {order?.payment?.tranRef || ``}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">Reference No :</label>
                        <div className="font-weight-normal">
                            {order?.payment?.referenceNo || ``}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">วันที่ชำระเงิน :</label>
                        <div className="font-weight-normal">
                            {<DateThai dateInline={order?.payment?.transactionDateTime || ``} showTime={true}/>}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">ช่องทางการชำระเงิน :</label>
                        <div className="font-weight-normal">
                            {order?.status === `success` && (
                                <>{(typeof order?.payment?.cardNo !== `undefined` && order?.payment?.cardNo !== ``) ? `Credit Card` : `PromptPay`}</>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">สถานะ :</label>
                        <div className="font-weight-bold d-flex align-items-center flex-wrap">
                            {/*{order?.status === `success` ? <span className="text-success">ชำระเงินแล้ว</span> :*/}
                            {/*    <span className="text-danger">รอชำระเงิน</span>}*/}


                            {order?.status === `success` ?
                                <span className="text-success">ชำระเงินแล้ว</span> :
                                (order?.status === `cancel` ? <span className="text-danger">ยกเลิก</span> :
                                    <span className="text-info">รอชำระเงิน</span>)}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">2C2P Invoice :</label>
                        <div className="font-weight-normal">
                            {order?.key || ``}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="h4">ข้อมูลส่วนตัว</div>
            <div className="row">
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">ชื่อ-นามสกุล :</label>
                        <div className="font-weight-normal">
                            {order?.name || ``}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">หมายเลขโทรศัพท์ :</label>
                        <div className="font-weight-normal">
                            {order?.phone || ``}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">อีเมล์ :</label>
                        <div className="font-weight-normal">
                            {order?.email || ``}
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <label className="text-primary mb-1">วันที่สั่งซื้อ :</label>
                        <div className="font-weight-normal">
                            {<DateThai date={order?.createdAt || ``} showTime={true}/>}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>

            <div className="h4">สรุปรายการสั่งซิ้อ</div>

            {order?.items?.length > 0 ? order?.items?.map(item => (
                <div key={item.id}>
                    <div className="row align-items-center mb-0">
                        <div className="col-6">
                            <div className="h6 d-inline mb-0 mr-1">{item?.name}</div>
                            {item?.status === 1 ?
                                <span className="badge badge-success font-weight-normal">ใช้งานแล้ว</span> :
                                <span className="badge badge-danger font-weight-normal">ยังไม่ใช้งาน</span>}
                        </div>
                        <div className="col-6 text-right">
                            <div className="h4 mb-1" style={{lineHeight: 1}}>
                                <CurrencyFormat value={item?.amount} displayType={'text'} thousandSeparator={true}/> บาท
                            </div>

                            {item?.discount !== `` && item?.discount !== 0 &&
                                <div className="text-muted" style={{textDecoration: "line-through"}}>
                                    <CurrencyFormat value={item?.cost || 0} displayType={'text'} thousandSeparator={true}/> บาท
                                </div>
                            }

                            {/*<div className="text-muted" style={{textDecoration: "line-through"}}>3,000 บาท</div>*/}
                        </div>
                    </div>
                    <div className="mb-3 mt-2">
                        {item?.packageDate && item?.packageDate !== `` && item?.packageDate !== `0001-01-01T00:00:00Z` && (
                            <>
                                <div className="text-primary">วันหมดอายุการใช้งาน:</div>
                                <DateThai date={item?.packageDate ?? ``}/>
                                {/*<DateThai date={item?.startDate}/> - <DateThai date={item?.endDate}/>*/}
                            </>
                        )}
                    </div>

                    <div className="row mb-4">
                        <div className="col-md-4">
                            <label htmlFor="">วันที่ใช้งาน :</label>
                            {/*{item?.usedDate}*/}
                            {item?.usedDate !== `` && item?.usedDate !== `0001-01-01T00:00:00Z` && moment(item?.usedDate).format(`YYYY-MM-DD`)}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="">Bill NO. :</label> {item?.billNo || ''}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="">Cashier Name :</label> {item?.cashierName || ''}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="">HN :</label> {item?.HN || ''}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="">Patient Name :</label> {item?.patientName || ''}
                        </div>
                        <div className="col-md-4">
                            <label htmlFor="">Memo :</label> {item?.memo || ''}
                        </div>
                    </div>
                    <hr/>
                </div>
            )) : (<></>)}


            <div className="row mb-1">
                <div className="col-6 text-primary">
                    ส่วนลด
                </div>
                <div className="col-6 text-primary text-right">
                    <CurrencyFormat value={order?.discount || 0} displayType={'text'} thousandSeparator={true}/> บาท
                </div>
            </div>
            <div className="row mb-2">
                <div className="col-6 h3 mb-0 ">
                    ราคาสุทธิ
                </div>
                <div className="col-6 h3 mb-0 text-right">
                    <CurrencyFormat value={order?.amount || 0} displayType={'text'} thousandSeparator={true}/> บาท
                </div>
            </div>

        </div>
    )
}

export default OrderView
