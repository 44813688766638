import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProfile, setUser} from "../../../features/reducer/user/userSlice";
import {useNavigate, useParams} from "react-router-dom";
import {getAsyncUser, newAsyncUser, updateAsyncUser} from "../../../features/reducer/user/userThunk";
import {FiPlusCircle} from "react-icons/fi";
import {MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_FILE_SIZE_MESSAGE, Roles} from "../../../common/config/app";
import {setModal} from "../../../features/reducer/utility";
import {RiCloseFill} from "react-icons/ri";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import ImageBoxUpload from "../../utility/ImageBoxUpload";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import PasswordStrengthBar from 'react-password-strength-bar';

function UserCreate({user, role}) {
    let {id} = useParams();
    const dispatch = useDispatch()
    const [data, setData] = useState(user)
    const [image, setImage] = useState('')
    const [imageInput, setImageInput] = useState(null)
    const [passwordStrong, setPasswordStrong] = useState(0)
    const profile = useSelector(getProfile)

    useEffect(() => {
        if (typeof id !== `undefined`) {
            dispatch(getAsyncUser({id}))
        } else {
            dispatch(setUser(null))
            setData(null)
        }
    }, [dispatch])

    useEffect(() => {
        if (user != null) {
            setData(user)
        }
    }, [user])


    const handleInput = (e) => {
        const {name, value} = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleChangeFile = (event, name) => {
        let files = event.target.files;
        if (typeof files[0]?.size == 'undefined') {
            setImage("")
            setImageInput(null)
            // setData({
            //     ...data,
            //     [name]: null
            // })
            return false
        }
        if (files[0].size / 1024 > MAX_UPLOAD_FILE_SIZE) {
            notificationErrorCustomMessage(MAX_UPLOAD_FILE_SIZE_MESSAGE, 'info')
            return false
        }
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            // console.log(e.target.result)
            setImage(e.target.result)
            setImageInput(files[0])
            // setData({
            //     ...data,
            //     [name]: files[0]
            // })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const form = new FormData()
        if (typeof data.id !== `undefined`) {
            form.append("id", data.id)
        }
        form.append("username", data?.username ?? '')
        form.append("name", data?.name ?? '')
        form.append("password", data?.password ?? '')
        form.append("email", data?.email ?? '')
        form.append("phone", data?.phone ?? '')
        form.append("status", data?.status ?? '')
        form.append("role", data?.role ?? '')
        if (imageInput != null)
            form.append("image", imageInput)

        // console.log(imageInput)

        let pass = data?.password ?? ''
        if (pass !== ``) {
            if (passwordStrong === 0) {
                notificationErrorCustomMessage(`รหัสผ่านไม่ปลอดภัย`, `warning`)
                return
            }
            if (pass !== data?.confirmPassword) {
                notificationErrorCustomMessage(`รหัสผ่านไม่ตรงกัน`, `warning`)
                return
            }
        }

        if (user == null) {
            if (passwordStrong === 0) {
                notificationErrorCustomMessage(`รหัสผ่านไม่ปลอดภัย`, `warning`)
                return
            }
            dispatch(newAsyncUser(form))
        } else {
            dispatch(updateAsyncUser({id: data.id, data: form}))
        }
    }

    const handlePasswordScore = score => {
        // console.log(score)
        setPasswordStrong(score)
    }

    return (<>
        <div className="row">
            <div className="col-lg-12">
                <form onSubmit={e => handleSubmit(e)}>
                    <div className="row">
                        <div className="col-lg-12">
                            <h6 className="mb-3">ข้อมูลการใช้งาน</h6>
                            <div className="row mb-4">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <TextField fullWidth label="ชื่อเข้าใช้งาน *" name="username" disabled={typeof data?.id !== `undefined`} onChange={e => handleInput(e)} value={data?.username ?? ''} size="small" variant="outlined"/>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <TextField type="password" inputProps={{
                                            autoComplete: 'new-password',
                                        }} fullWidth label="รหัสผ่าน" name="password" onChange={e => handleInput(e)} size="small" variant="outlined"/>

                                        <PasswordStrengthBar minLength={6} onChangeScore={handlePasswordScore} password={data?.password || ``}/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <TextField type="password" inputProps={{
                                            autoComplete: 'new-password',
                                        }} fullWidth label="ยืนยันรหัสผ่าน" name="confirmPassword" onChange={e => handleInput(e)} size="small" variant="outlined"/>
                                    </div>
                                </div>
                            </div>
                            <h6 className="mb-3">ข้อมูลส่วนตัว</h6>
                            <div className="row mb-4">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <TextField fullWidth label="ชื่อ-นามสกุล *" name="name" onChange={e => handleInput(e)} value={data?.name ?? ''} size="small" variant="outlined"/>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <TextField fullWidth label="เบอร์โทรศัพท์ *" name="phone" onChange={e => handleInput(e)} value={data?.phone ?? ''} size="small" variant="outlined"/>
                                    </div>
                                </div>

                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <TextField fullWidth label="อีเมล์ *" name="email" onChange={e => handleInput(e)} value={data?.email ?? ''} size="small" variant="outlined"/>
                                    </div>
                                </div>


                                {profile?.role === `1-2-3` && (
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <FormControl fullWidth>
                                                <InputLabel id="role-select-label">สิทธิ์การเช้าใช้งาน</InputLabel>
                                                <Select
                                                    size="small"
                                                    labelId="role-select-label"
                                                    id="role-simple-select"
                                                    defaultValue={data?.role ?? ''}
                                                    value={data?.role ?? ''}
                                                    label="สิทธิ์การเช้าใช้งาน"
                                                    name="role"
                                                    onChange={e => handleInput(e)}
                                                >
                                                    {Roles.map((v, i) => (
                                                        <MenuItem key={i} value={v.key}>{v.label}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}


                            </div>
                        </div>
                        <div className="col-lg-4 d-none">
                            <ImageBoxUpload
                                name="image"
                                label="เลือกรูปภาพ"
                                note="ขนาดแนะนำ 80 x 80 px"
                                handleChangeFile={handleChangeFile}
                                initImage={data?.image}
                                uploadImage={image}
                            />
                        </div>
                    </div>

                    <div className="mt-4 d-flex align-items-center">
                        <button className="btn btn-primary mr-2" onClick={e => handleSubmit(e)}>
                            <FiPlusCircle/> {user != null ? `แก้ไข` : `เพิ่ม`} ข้อมูล
                        </button>
                        <button type="button" className="btn btn-danger" onClick={() => dispatch(setModal(false))}>
                            <RiCloseFill/> ปิดหน้าต่าง
                        </button>
                    </div>

                </form>
            </div>
        </div>
    </>);
}

export default UserCreate;
