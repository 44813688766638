import {useEffect} from "react";
import localStorageService from "../../services/localStorageService";

const Logout = () => {
    const localStorage = localStorageService.getService()
    useEffect(() => {
        localStorage.forgetToken()
        window.location.href = `/login`
    }, [])

    return (
        <></>
    )
}

export default Logout
