import {useParams} from "react-router-dom";
import {FiArrowLeft, FiCalendar, FiEye, FiImage, FiPlus, FiSave, FiSettings, FiStar} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {
  getClinics,
  getDoctor,
  getDoctorType,
  getSpecialties,
  resetDoctor
} from "../../../features/reducer/doctor/doctorSlice";
import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {
  fetchAsyncDoctors,
  getAsyncDoctor,
  getAsyncDoctorClinics,
  getAsyncDoctorSpecialties,
  newAsyncDoctor,
  updateAsyncDoctor
} from "../../../features/reducer/doctor/doctorThunk";
import {setActiveMenu} from "../../../features/reducer/utility";
import ImageBoxUpload from "../../utility/ImageBoxUpload";
import TinyMCE from "../../utility/TinyMCE";
import organs from "../../../common/data/organs.json";
import {Languages, MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_FILE_SIZE_MESSAGE} from "../../../common/config/app";
import {TagsInput} from "react-tag-input-component";
import {getFrontUrl} from "../../../common/api/postApi";


const initImageState = {
  image: "", imageThumbnail: "",
}

const initDataState = {
  firstName: "", lastName: "", content: [{
    title: ``, item: ``, updated: null
  }], status: "published"
}

const initCalendar = [
  {name: `จันทร์`, morning: ``, afternoon: ``},
  {name: `อังคาร`, morning: ``, afternoon: ``},
  {name: `พุธ`, morning: ``, afternoon: ``},
  {name: `พฤหัสบดี`, morning: ``, afternoon: ``},
  {name: `ศุกร์`, morning: ``, afternoon: ``},
  {name: `เสาร์`, morning: ``, afternoon: ``},
  {name: `อาทิตย์`, morning: ``, afternoon: ``}
]

const DoctorCreate = () => {
  // ** Params
  let {id, type} = useParams();

  // ** Store Vars
  const dispatch = useDispatch()
  const doctor = useSelector(getDoctor)
  const specialties = useSelector(getSpecialties)
  const clinics = useSelector(getClinics)
  const doctorType = useSelector(getDoctorType)

  // ** States
  const [data, setData] = useState(initDataState)
  const [calendars, setCalendars] = useState(initCalendar)
  const [image, setImage] = useState(initImageState)
  const [imageInput, setImageInput] = useState(initImageState)
  const [errors, setErrors] = useState([])
  const [title, setTitle] = useState("")
  const [frontUrl, setFrontUrl] = useState(process.env.REACT_APP_FRONTEND)
  const [saved, setSaved] = useState(true)

  // ** Effect
  useEffect(() => {
    // dispatch(setActiveMenu(`doctor`));
  }, [dispatch])

  useEffect(() => {
    dispatch(setActiveMenu(`doctor${typeof type !== `undefined` ? `-${type}` : ``}`))
    const d = doctorType[type] ?? ``
    if (d !== ``) {
      setTitle(d)
      document.title = d
    } else {
      window.location.href = `/`
      document.title = `ข้อมูลแพทย์`
    }

  }, [dispatch, type]);

  useEffect(() => {
    dispatch(getAsyncDoctorSpecialties({}))
    dispatch(getAsyncDoctorClinics({}))
  }, [dispatch])

  useEffect(() => {
    if (isUpdate()) {
      // alert(id)
      dispatch(getAsyncDoctor(id))
    }
    return () => {
      setData(initDataState)
      dispatch(resetDoctor())
    }
  }, [dispatch, id])

  useEffect(() => {
    if (typeof doctor?.id !== `undefined`) {
      // console.log(doctor)
      let d = {
        ...doctor,
        content: doctor.content.map(item => {
          return {...item, updated: moment().unix()}
        })
      }
      setData(d)
      if (typeof doctor.calendars !== `undefined` && doctor.calendars !== null) {
        setCalendars(doctor.calendars)
      }
    } else {
      setData(initDataState)
    }
  }, [doctor])

  useEffect(() => {
    getFrontUrl().then(resp => {
      const url = resp?.data?.url
      setFrontUrl(url)
    })
  }, [])


  // ** Functions
  const isUpdate = () => {
    return typeof id !== `undefined` && id !== `` && id !== null
  }

  const handleInput = (e) => {
    const {name, value} = e.target
    if (typeof errors[name] !== `undefined`) {
      delete errors[name]
      setErrors(errors)
    }
    setData({...data, [name]: value})
    setSaved(false)
  }

  const handleSetTags = (v) => {
    setData({
      ...data,
      tags: v
    })
    // setSaved(false)
  }

  const handleInputSubtitle = (e, key) => {
    let d = data?.content?.map((item, index) => {
      if (key === index) {
        return {...item, title: e.target.value}
      }
      return item
    })
    setData({...data, content: d})
    setSaved(false)
  }

  const handleCalendar = (e, key) => {
    const {value, name} = e.target
    let input = calendars?.map((item, index) => {
      if (key === index) {
        return {...item, [name]: value}
      }
      return item
    })
    setCalendars(input)
    setSaved(false)
  }

  const handleSubInput = (e, key) => {
    setData({...data, [key]: {...data[key], [e.target.name]: e.target.value}})
    // setSaved(false)
  }

  const handleDate = (e) => {
    const {value, name} = e.target
    setData({
      ...data, [name]: value
    })
    // setSaved(false)
  }

  const handleSetContent = (key, content) => {
    let d = data?.content?.map((item, index) => {
      if (key === index) {
        return {...item, item: content}
      }
      return item
    })
    setData({...data, content: d})
    // setSaved(false)
  }

  const handleAddContentRow = () => {
    let d = data?.content?.concat([{item: ``, updated: null}])
    setData({...data, content: d})
    setSaved(false)
  }

  const handleRemoveContentRow = (key) => {
    let d = [...data?.content]
    d.splice(key, 1)
    d = d.map((item, index) => {
      item.updated = moment().unix()
      return item
    })
    setData({...data, content: d})
    setSaved(false)
  }

  const handleChangeFile = (event, name) => {
    let files = event.target.files;
    if (typeof files[0]?.size == 'undefined') {
      setImage({...image, [name]: ""})
      setImageInput({...imageInput, [name]: null})
      return false
    }
    if (files[0].size / 1024 > MAX_UPLOAD_FILE_SIZE) {
      notificationErrorCustomMessage(MAX_UPLOAD_FILE_SIZE_MESSAGE, 'info')
      return false
    }
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage({...image, [name]: e.target.result})
      setImageInput({...imageInput, [name]: files[0]})
    }
    setSaved(false)
  }


  const handleSubmit = () => {

    let error = {}
    // if (data.prefix === ``) {
    //   error[`prefix`] = `กรุณากรอกคำนำหน้าชื่อ`
    // }

    if (data.name === ``) {
      error[`name`] = `กรุณากรอกชื่อจริง`
    }

    if (data.lastName === ``) {
      error[`lastName`] = `กรุณากรอกนามสกุล`
    }

    if (error !== null && Object.keys(error).length > 0) {
      setErrors(error)
      return false
    }

    const thaiDaysOrder = ["จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์", "อาทิตย์"];
    const dayName = [];
    const filteredCalendars = calendars.filter((calendar) => {
      if (!dayName.includes(calendar.name) && thaiDaysOrder.includes(calendar.name)) {
        dayName.push(calendar.name);
        return true;
      }
      return false;
    });

    const missingDays = thaiDaysOrder.filter(day => !dayName.includes(day));
    if (missingDays) {
      missingDays.map(day => {
        filteredCalendars.push({name: day, morning: '', afternoon: ''});
      })
    }



    filteredCalendars.sort((a, b) => {
      const indexA = thaiDaysOrder.indexOf(a.name);
      const indexB = thaiDaysOrder.indexOf(b.name);
      return indexA - indexB;
    });


    const form = new FormData()
    form.append("firstName", data.firstName)
    form.append("lastName", data.lastName)
    form.append("prefix", data?.prefix ?? '')
    form.append("number", data?.number ?? '')
    form.append("languages", data?.languages ?? '')
    form.append("treatmentCenter", data?.treatmentCenter ?? '')
    form.append("specialty", data?.specialty ?? '')
    form.append("type", data?.type ?? '')
    form.append("clinic", data?.clinic ?? '')
    form.append("branchSpecialty", data?.branchSpecialty ?? '')
    form.append("subBranchSpecialty", data?.subBranchSpecialty ?? '')
    form.append("content", JSON.stringify(data.content))
    form.append("calendars", JSON.stringify(filteredCalendars))
    form.append("doctorType", type)
    form.append("sort", data?.sort ?? 0)
    form.append("sortRelated", parseInt(data?.sortRelated ?? 0))
    form.append("healthyRelate", data?.healthyRelate)
    form.append("tags", JSON.stringify(data?.tags ?? []))
    form.append("status", data?.status ?? 'draft')

    // console.log(JSON.stringify(calendars))

    Object.keys(imageInput).forEach(key => {
      form.append(key, imageInput[key])
    })
    const dt = {
      form: form,
      type: type,
    }
    if (isUpdate()) {
      dispatch(updateAsyncDoctor({...dt, id: data.id}))
    } else {
      dispatch(newAsyncDoctor(dt))
    }
    setSaved(true)
  }

  const handleChangeLanguage = lang => {

    if (!saved) {
      if (!window.confirm(`คุณยังไม่ได้บันทึกก่อนเปลี่ยนภาษา ยังต้องการเปลี่ยนภาษาหรือไม่?`)) {
        return
      }
    }

    if (lang == `th`) {
      window.location.href = `/personal/${type}/${data.code}/edit`;
    } else {
      window.location.href = `/personal/${lang}/${type}/${data.code}/edit`;
    }
  }


  return <>
    <div className="row">
      <div className="col-lg-9">
        <div className="box mb-4">
          <div className="box-header d-flex align-items-center justify-content-between">
            <div>
              <FiStar/> {!isUpdate() ? `เพิ่ม` : `แก้ไข`}แพทย์
            </div>
            <div className="text-right">
              <a href={`/personal/${type}`} className={`btn btn-secondary mr-1 blowc`}><FiArrowLeft/> ย้อนกลับ</a>
              {typeof data?.previewCode !== `undefined` && data?.previewCode !== `` && typeof frontUrl !== `undefined` &&
                <a href={`${frontUrl}/view/preview/doctor/?code=${data?.code}&t=${data?.previewCode ?? ``}`} target="_blank" className="btn btn-success mr-1"><FiEye/> ดูตัวอย่าง</a>
              }
              <button onClick={() => handleSubmit()} className="btn btn-primary"><FiSave/> บันทึก</button>
            </div>
          </div>
          <div>
            <div className="mb-4">
              {typeof data?.id !== `undefined` && Languages.map(l => <>
                <button onClick={() => handleChangeLanguage(l.code)} className={`btn btn-${`th` == l.code ? `success` : `outline-success`} px-5 mr-2 mb-3`}>{l.name}</button>
              </>)}
            </div>
            <div className="row">
              <div className="col-lg-2 d-none">
                <div className={`mb-3 ${typeof errors?.prefix !== `undefined` && `has-error`}`}>
                  <TextField required fullWidth label="คำนำหน้า" name="prefix" value={data?.prefix || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  {typeof errors?.prefix !== `undefined` &&
                    <div className="text-danger mt-1"><small>{errors?.prefix}</small></div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`mb-3 ${typeof errors?.firstName !== `undefined` && `has-error`}`}>
                  <TextField required fullWidth label="ชื่อจริง" name="firstName" value={data?.firstName?.trim() || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  {typeof errors?.firstName !== `undefined` &&
                    <div className="text-danger mt-1"><small>{errors?.firstName}</small></div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className={`mb-3 ${typeof errors?.lastName !== `undefined` && `has-error`}`}>
                  <TextField required fullWidth label="นามสกุล" name="lastName" value={data?.lastName?.trim() || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  {typeof errors?.lastName !== `undefined` &&
                    <div className="text-danger mt-1"><small>{errors?.lastName}</small></div>}
                </div>
              </div>
            </div>
            {type === `doctor` ? <>
              <div className="row mb-4">
                <div className="col-lg-4">
                  <div className="mb-3">
                    <TextField fullWidth label="เลขใบประกอบ" name="number" value={data?.number || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <TextField fullWidth label="ภาษา" name="languages" value={data?.languages || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <TextField fullWidth label="ศูนย์การรักษา" name="treatmentCenter" value={data?.treatmentCenter || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <TextField fullWidth label="ความเชี่ยวชาญ" name="specialty" value={data?.specialty || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="mb-3">
                    <TextField fullWidth label="ประเภทของแพทย์" name="type" value={data?.type || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  </div>
                </div>
              </div>
            </> : <>
              <div className="row mb-4">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <TextField fullWidth label="โรงพยาบาล" name="treatmentCenter" value={data?.treatmentCenter || ''} onChange={e => handleInput(e)} variant="outlined"/>
                  </div>
                </div>
              </div>
            </>}


            <div className="mb-4">
              {data?.content.map((content, index) => <div key={`editor-${index}`} className="mb-5">
                <div className="mb-2">
                  <TextField fullWidth label="หัวข้อย่อย เช่น การศึกษา, รายละเอียดเพิ่มเติม" name="title" value={content?.title} onChange={e => handleInputSubtitle(e, index)} variant="outlined"/>
                </div>
                <TinyMCE content={content?.item} keyIndex={index} setContent={handleSetContent} handleRemoveContentRow={handleRemoveContentRow}/>
              </div>)}
            </div>
            <div className="text-right">
              <button className="btn btn-success" onClick={() => handleAddContentRow()}>
                <FiPlus/>เพิ่ม Editor
              </button>
            </div>

          </div>
        </div>
      </div>
      <div className="col-lg-3">

        <div className="box mb-4">
          <div className="box-header">
            <FiSettings/> ตั้งค่า
          </div>
          <div>
            {/*<div className="form-group">*/}
            {/*    <TextField*/}
            {/*        fullWidth*/}
            {/*        id="datetime-local"*/}
            {/*        label="วันที่เผยแพร่"*/}
            {/*        type="datetime-local"*/}
            {/*        name="publishDate"*/}
            {/*        onChange={e => handleDate(e)}*/}
            {/*        value={data?.publishDate ?? moment(new Date()).format("YYYY-MM-DDTHH:mm")}*/}
            {/*    />*/}
            {/*</div>*/}
            <div className="form-group">
              <label className="mb-1">กำหนดสถานะ</label>
              <select name="status" onChange={e => handleInput(e)} value={data?.status || `draft`} className="form-control">
                <option value="published">เผยแพร่</option>
                <option value="draft">แบบร่าง</option>
              </select>
            </div>
            <div className="form-group">
              <label className="mb-1">ลำดับการแสดงผล</label>
              <input type="number" name="sort" onChange={e => handleInput(e)} value={data?.sort || 0} className="form-control"/>
            </div>

            <div className="form-group">
              <label className="mb-1">การแสดงผลในบทความสุขภาพ</label>
              <select name="healthyRelate" onChange={e => handleInput(e)} value={data?.healthyRelate || `0`} className="form-control">
                <option value="0">ไม่แสดง</option>
                <option value="1">แสดง</option>
              </select>
            </div>
            {data?.healthyRelate === `1` &&
              <div className="form-group">
                <label className="mb-1">ลำดับการแสดงผลในบทความสุขภาพ</label>
                <input type="number" name="sortRelated" onChange={e => handleInput(e)} value={data?.sortRelated || 0} className="form-control"/>
              </div>
            }

            <div className="form-group">
              <label className="mb-1">กำหนดคำค้น หรือ Tags</label>
              <TagsInput
                value={data?.tags || []}
                onChange={handleSetTags}
                name="tags"
                placeHolder="กำหนดคำค้น หรือ Keywords"
              />
              <small>ช่วนในการค้นหาในหน้าเว็บจากช่องค้นหาได้ง่ายขึ้น</small>
            </div>

          </div>
        </div>

        {type === `doctor` &&
          <div className="box">
            <div className="box-header">
              <FiSettings/> กำหนดคลินิคและสาขาที่เชียวชาญ
            </div>
            <div>
              <div className="form-group">
                <label>คลินิค</label>
                <select className="form-control" name="clinic" value={data?.clinic || 0} onChange={e => handleInput(e)}>
                  <option value={0}>เลือก</option>
                  {clinics.length > 0 && clinics.map(specialty =>
                    <option key={specialty.code} value={specialty.code}>{specialty.name}</option>)}
                </select>
              </div>
              <div className="form-group">
                <label>สาขาที่เชียวชาญ</label>
                <select className="form-control" name="branchSpecialty" value={data?.branchSpecialty || 0} onChange={e => handleInput(e)}>
                  <option value={0}>เลือก</option>
                  {specialties.length > 0 && specialties.map(specialty =>
                    <option key={specialty.code} value={specialty.code}>{specialty.name}</option>)}
                </select>
              </div>
              {typeof data?.branchSpecialty !== `undefined` && data?.branchSpecialty != 0 && <>
                <div className="form-group">
                  <label>สาขาย่อยที่เชียวชาญ</label>
                  <select className="form-control" name="subBranchSpecialty" value={data?.subBranchSpecialty || 0} onChange={e => handleInput(e)}>
                    <option value={0}>เลือก</option>
                    {specialties.length > 0 && specialties.map(specialty => specialty.code == data?.branchSpecialty && typeof specialty?.sub !== `undefined` && specialty.sub.length > 0 && specialty.sub.map(sub => (
                      <option key={sub.code} value={sub.code}>{sub.name}</option>
                    )))}
                  </select>
                </div>
              </>}
            </div>
          </div>
        }
        <div className="box">
          <div className="box-header">
            <FiImage/> กำหนดรูปภาพ
          </div>
          <div>
            <div className="row">

              <div className="col-lg-6">
                <ImageBoxUpload
                  name="image"
                  label="เลือกรูปภาพ"
                  note={`ขนาดแนะนำ 250 x 250 px`}
                  handleChangeFile={handleChangeFile}
                  initImage={data?.image}
                  uploadImage={image?.image}
                />
              </div>
              <div className="col-lg-6">
                <ImageBoxUpload
                  name="imageThumbnail"
                  label="เลือกรูปภาพหน้าปก"
                  note="ขนาดแนะนำ 450 x 670 px"
                  handleChangeFile={handleChangeFile}
                  initImage={data?.imageThumbnail}
                  uploadImage={image?.imageThumbnail}
                />
              </div>
            </div>
          </div>
        </div>
        {type === `doctor` &&
          <div className="box">
            <div className="box-header">
              <FiCalendar/> ตารางการปฏิบัติงาน
            </div>
            <div>
              {calendars.length > 0 && calendars.map((calendar, key) => <div className="row row-sm align-items-center">
                <div className="col-2">
                  <div className="form-group">{calendar.name}</div>
                </div>
                <div className="col-5">
                  <div className="form-group">
                    <input type="text" className="form-control" value={calendar.morning || ''} onChange={e => handleCalendar(e, key)} name="morning" placeholder="ช่วงเช้า"/>
                  </div>
                </div>
                <div className="col-5">
                  <div className="form-group">
                    <input type="text" className="form-control" value={calendar.afternoon || ''} onChange={e => handleCalendar(e, key)} name="afternoon" placeholder="ช่วงบ่าย"/>
                  </div>
                </div>
              </div>)}

            </div>
          </div>
        }
      </div>
    </div>
  </>
}

export default DoctorCreate
