import {useParams} from "react-router-dom";
import {FiCalendar, FiImage, FiPlus, FiSave, FiSettings, FiStar} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {
    getClinics,
    getDoctor,
    getDoctorType,
    getSpecialties,
    resetDoctor
} from "../../../features/reducer/doctor/doctorSlice";
import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import moment from "moment";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import {
    fetchAsyncDoctors,
    getAsyncDoctor,
    getAsyncDoctorClinics,
    getAsyncDoctorSpecialties,
    newAsyncDoctor,
    updateAsyncDoctor, updateDoctorLangAsync
} from "../../../features/reducer/doctor/doctorThunk";
import {setActiveMenu} from "../../../features/reducer/utility";
import ImageBoxUpload from "../../utility/ImageBoxUpload";
import TinyMCE from "../../utility/TinyMCE";
import organs from "../../../common/data/organs.json";
import {Languages} from "../../../common/config/app";


const initImageState = {
    image: "", imageThumbnail: "",
}

const initDataState = {
    firstName: "", lastName: "", content: [{
        title: ``, item: ``, updated: null
    }], status: "published"
}

const initCalendar = [
    {name: `จันทร์`, morning: ``, afternoon: ``},
    {name: `อังคาร`, morning: ``, afternoon: ``},
    {name: `พุธ`, morning: ``, afternoon: ``},
    {name: `พฤหัสบดี`, morning: ``, afternoon: ``},
    {name: `ศุกร์`, morning: ``, afternoon: ``},
    {name: `เสาร์`, morning: ``, afternoon: ``},
    {name: `อาทิตย์`, morning: ``, afternoon: ``}
]

const DoctorEditLanguage = () => {
    // ** Params
    let {id, type, lang} = useParams();

    // ** Store Vars
    const dispatch = useDispatch()
    const doctor = useSelector(getDoctor)
    const specialties = useSelector(getSpecialties)
    const clinics = useSelector(getClinics)
    const doctorType = useSelector(getDoctorType)

    // ** States
    const [data, setData] = useState(initDataState)
    const [calendars, setCalendars] = useState(initCalendar)
    const [image, setImage] = useState(initImageState)
    const [imageInput, setImageInput] = useState(initImageState)
    const [errors, setErrors] = useState([])
    const [title, setTitle] = useState("")
    const [saved, setSaved] = useState(true)
    const [currentLang, setCurrentLang] = useState(``)

    // ** Effect
    useEffect(() => {
        // dispatch(setActiveMenu(`doctor`));
    }, [dispatch])

    useEffect(() => {
        setCurrentLang(Languages.find(l => l.code == lang))
    }, [lang])

    useEffect(() => {
        dispatch(setActiveMenu(`doctor${typeof type !== `undefined` ? `-${type}` : ``}`))
        const d = doctorType[type] ?? ``
        if (d !== ``) {
            setTitle(d)
            document.title = d
        } else {
            window.location.href = `/`
            document.title = `ข้อมูลแพทย์`
        }

    }, [dispatch, type]);

    useEffect(() => {
        dispatch(getAsyncDoctorSpecialties({}))
        dispatch(getAsyncDoctorClinics({}))
    }, [dispatch])

    useEffect(() => {
        dispatch(getAsyncDoctor(id))
        return () => {
            setData(initDataState)
            dispatch(resetDoctor())
        }
    }, [dispatch, id])

    useEffect(() => {
        if (typeof doctor?.id !== `undefined`) {
            // console.log(doctor)

            const language = doctor?.otherLanguages
            const dtl = (language !== null && typeof language === `object`) ? doctor?.otherLanguages[currentLang.code] : {}

            const contents = typeof dtl?.content !== `undefined` ? dtl.content : [{
                title: ``, item: ``, updated: null
            }]

            let d = {
                ...dtl,
                id: doctor?.id ?? ``,
                code: doctor?.code ?? ``,
                fullNameTH: doctor?.fullName ?? ``,
                content: contents.map(item => {
                    return {...item, updated: moment().unix()}
                })
            }
            setData(d)
        } else {
            setData(initDataState)
        }
    }, [doctor])


    // ** Functions

    const handleInput = (e) => {
        const {name, value} = e.target
        if (typeof errors[name] !== `undefined`) {
            delete errors[name]
            setErrors(errors)
        }
        setData({...data, [name]: value})
        setSaved(false)
    }

    const handleInputSubtitle = (e, key) => {
        let d = data?.content?.map((item, index) => {
            if (key === index) {
                return {...item, title: e.target.value}
            }
            return item
        })
        setData({...data, content: d})
        setSaved(false)
    }

    const handleCalendar = (e, key) => {
        const {value, name} = e.target
        let input = calendars?.map((item, index) => {
            if (key === index) {
                return {...item, [name]: value}
            }
            return item
        })
        setCalendars(input)
    }

    const handleSubInput = (e, key) => {
        setData({...data, [key]: {...data[key], [e.target.name]: e.target.value}})
    }

    const handleDate = (e) => {
        const {value, name} = e.target
        setData({
            ...data, [name]: value
        })
    }

    const handleSetContent = (key, content) => {
        let d = data?.content?.map((item, index) => {
            if (key === index) {
                return {...item, item: content}
            }
            return item
        })
        setData({...data, content: d})
        setSaved(false)
    }

    const handleAddContentRow = () => {
        let d = data?.content?.concat([{item: ``, updated: null}])
        setData({...data, content: d})
        setSaved(false)
    }

    const handleRemoveContentRow = (key) => {
        let d = [...data?.content]
        d.splice(key, 1)
        d = d.map((item, index) => {
            item.updated = moment().unix()
            return item
        })
        setData({...data, content: d})
        setSaved(false)
    }


    const handleSubmit = () => {

        let error = {}
        if (data.prefix === ``) {
            error[`prefix`] = `กรุณากรอกคำนำหน้าชื่อ`
        }

        if (data.name === ``) {
            error[`name`] = `กรุณากรอกชื่อจริง`
        }

        if (data.lastName === ``) {
            error[`lastName`] = `กรุณากรอกนามสกุล`
        }

        if (error !== null && Object.keys(error).length > 0) {
            setErrors(error)
            return false
        }

        const form = new FormData()
        form.append("firstName", data.firstName)
        form.append("lastName", data.lastName)
        form.append("prefix", data?.prefix ?? '')
        form.append("number", data?.number ?? '')
        form.append("languages", data?.languages ?? '')
        form.append("treatmentCenter", data?.treatmentCenter ?? '')
        form.append("specialty", data?.specialty ?? '')
        form.append("type", data?.type ?? '')
        form.append("clinic", data?.clinic ?? '')
        form.append("branchSpecialty", data?.branchSpecialty ?? '')
        form.append("subBranchSpecialty", data?.subBranchSpecialty ?? '')
        form.append("content", JSON.stringify(data.content))
        form.append("calendars", JSON.stringify(calendars))
        form.append("doctorType", type)
        form.append("status", data?.status ?? 'draft')

        // console.log(JSON.stringify(calendars))

        Object.keys(imageInput).forEach(key => {
            form.append(key, imageInput[key])
        })
        const dt = {
            form: form,
            type: type,
            lang: lang
        }
        dispatch(updateDoctorLangAsync({...dt, id: data.id}))
        setSaved(true)

    }

    const handleChangeLanguage = lang => {
        if (!saved) {
            if (!window.confirm(`คุณยังไม่ได้บันทึกก่อนเปลี่ยนภาษา ยังต้องการเปลี่ยนภาษาหรือไม่?`)) {
                return
            }
        }

        if (lang == `th`) {
            window.location.href = `/personal/${type}/${data.code}/edit`;
        } else {
            window.location.href = `/personal/${lang}/${type}/${data.code}/edit`;
        }
    }


    return (<>
        <div className="row">
            <div className="col-lg-9">
                <div className="box mb-4">
                    <div className="box-header d-flex align-items-center justify-content-between">
                        <div>
                            <FiStar/> แก้ไขแพทย์ {data?.fullNameTH || ``} ({currentLang?.name ?? ``})
                        </div>
                        <div className="text-right">
                            <button onClick={() => handleSubmit()} className="btn btn-primary"><FiSave/> บันทึก</button>
                        </div>
                    </div>
                    <div>

                        <div className="mb-4">

                            {typeof data?.id !== `undefined` && Languages.map(l => (
                                <>
                                    <button onClick={() => handleChangeLanguage(l.code)} className={`btn btn-${lang == l.code ? `success` : `outline-success`} px-5 mr-2 mb-3`}>{l.name}</button>
                                </>
                            ))}
                        </div>

                        <div className="row">
                            <div className="col-lg-2">
                                <div className={`mb-3 ${typeof errors?.prefix !== `undefined` && `has-error`}`}>
                                    <TextField required fullWidth label="คำนำหน้า" name="prefix" value={data?.prefix || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                    {typeof errors?.prefix !== `undefined` &&
                                        <div className="text-danger mt-1"><small>{errors?.prefix}</small></div>}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className={`mb-3 ${typeof errors?.firstName !== `undefined` && `has-error`}`}>
                                    <TextField required fullWidth label="ชื่อจริง" name="firstName" value={data?.firstName || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                    {typeof errors?.firstName !== `undefined` &&
                                        <div className="text-danger mt-1"><small>{errors?.firstName}</small></div>}
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className={`mb-3 ${typeof errors?.lastName !== `undefined` && `has-error`}`}>
                                    <TextField required fullWidth label="นามสกุล" name="lastName" value={data?.lastName || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                    {typeof errors?.lastName !== `undefined` &&
                                        <div className="text-danger mt-1"><small>{errors?.lastName}</small></div>}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <TextField fullWidth label="เลขใบประกอบ" name="number" value={data?.number || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <TextField fullWidth label="ภาษา" name="languages" value={data?.languages || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <TextField fullWidth label="ศูนย์การรักษา" name="treatmentCenter" value={data?.treatmentCenter || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <TextField fullWidth label="ความเชี่ยวชาญ" name="specialty" value={data?.specialty || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="mb-3">
                                    <TextField fullWidth label="ประเภทของแพทย์" name="type" value={data?.type || ''} onChange={e => handleInput(e)} variant="outlined"/>
                                </div>
                            </div>
                        </div>


                        <div className="mb-4">
                            {data?.content.map((content, index) => (
                                <div key={`editor-${index}`} className="mb-5">
                                    <div className="mb-2">
                                        <TextField fullWidth label="หัวข้อย่อย เช่น การศึกษา, รายละเอียดเพิ่มเติม" name="title" value={content?.title} onChange={e => handleInputSubtitle(e, index)} variant="outlined"/>
                                    </div>
                                    <TinyMCE content={content?.item} keyIndex={index} setContent={handleSetContent} handleRemoveContentRow={handleRemoveContentRow}/>
                                </div>
                            ))}
                        </div>
                        <div className="text-right">
                            <button className="btn btn-success" onClick={() => handleAddContentRow()}>
                                <FiPlus/>เพิ่ม Editor
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default DoctorEditLanguage
