import {FiMenu, FiUser} from "react-icons/fi";
import {setOpenModalContent} from "../../features/reducer/utility";
import UserCreate from "../admin/user/UserCreate";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../features/reducer/user/userSlice";
import {Dropdown} from "react-bootstrap";
import {RiLockLine} from "react-icons/ri";
import {BiDonateHeart, BiMessageSquareDetail, BiStar} from "react-icons/bi";
import {Link} from "react-router-dom";
import {Tooltip} from "@mui/material";
import {MdBiotech, MdOutlineThumbUpOffAlt} from "react-icons/md";
import {imageSrc} from "../../common/functions/usage";

const Navbar = ({handleOpenSideMenu}) => {
    // store **
    const dispatch = useDispatch()
    const profile = useSelector(getProfile)
    return (
        <>
            <div className="header">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-sm-6 col-3 d-flex align-items-center">
                        <div className="menu-bars shortcut" onClick={handleOpenSideMenu}>
                            <FiMenu/>
                        </div>
                        {/*<Link to={`/news`} className="shortcut">*/}
                        {/*    <Tooltip title="ข่าวสารและกิจกรรม" arrow>*/}
                        {/*        <div>*/}
                        {/*            <BiMessageSquareDetail/>*/}
                        {/*        </div>*/}
                        {/*    </Tooltip>*/}
                        {/*</Link>*/}
                        {/*<Link to={`/activity`} className="shortcut">*/}
                        {/*    <Tooltip title="กิจกรรมเพื่อสังคม" arrow>*/}
                        {/*        <div>*/}
                        {/*            <BiStar/>*/}
                        {/*        </div>*/}
                        {/*    </Tooltip>*/}
                        {/*</Link>*/}
                        {/*<Link to={`/clinic`} className="shortcut">*/}
                        {/*    <Tooltip title="ศูนย์รักษาโรค" arrow>*/}
                        {/*        <div>*/}
                        {/*            <BiDonateHeart/>*/}
                        {/*        </div>*/}
                        {/*    </Tooltip>*/}
                        {/*</Link>*/}
                        {/*<Link to={`/technology`} className="shortcut">*/}
                        {/*    <Tooltip title="เทคโนโลยีของเรา" arrow>*/}
                        {/*        <div>*/}
                        {/*            <MdBiotech/>*/}
                        {/*        </div>*/}
                        {/*    </Tooltip>*/}
                        {/*</Link>*/}
                        {/*<Link to={`/impression`} className="shortcut">*/}
                        {/*    <Tooltip title="ความประทับใจ" arrow>*/}
                        {/*        <div>*/}
                        {/*            <MdOutlineThumbUpOffAlt/>*/}
                        {/*        </div>*/}
                        {/*    </Tooltip>*/}
                        {/*</Link>*/}
                        {/*<DateCountdown dateTo='2028-03-03' callback={()=>alert('Hello')}  />*/}
                        {/*<div className="d-none d-sm-block"><Countdown deadline={config?.expiredAt}/></div>*/}
                    </div>
                    <div className="col-lg-6 col-sm-6 col-9 d-flex align-items-center justify-content-end">
                        {/*<div className="logout mr-2">*/}
                        {/*    <Link to={`/logout`} className="btn btn-block btn-sm btn-danger"><RiLockLine/> ออกจากระบบ</Link>*/}
                        {/*</div>*/}
                        <div className="profile">
                            <a href="#" className="text-right" onClick={() => dispatch(setOpenModalContent({
                                title: `แก้ไข ${profile.username}`,
                                content: <UserCreate role={profile?.roleName} user={profile}/>
                            }))}>
                                <div className="mb-0 text-white font-weight-bold">{profile?.name}</div>
                                <small className="text-muted">
                                    {profile?.role == `admin` ? (
                                        <span className="text-danger"><FiUser/> ผู้ดูแลระบบ</span>) : (
                                        <span className="text-success"><FiUser/> พนักงาน</span>)}
                                </small>
                            </a>
                            <div className="img ml-2"></div>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <div className="img-profile" style={{width: `40px`}}>
                                        <div className="img-card-placeholder img-circle img-card-s1">
                                            <div className="image" style={{backgroundImage: `url(${imageSrc(profile?.image ?? `/assets/images/user.png`)})`}}></div>
                                        </div>
                                        {/*<img src={} width={40} alt="user"/>*/}
                                    </div>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/prfile" onClick={() => dispatch(setOpenModalContent({
                                        title: `แก้ไข ${profile.username}`,
                                        content: <UserCreate role={profile?.roleName} user={profile}/>
                                    }))}><FiUser/> แก้ไขข้อมูลส่วนตัว</Dropdown.Item>
                                    <Dropdown.Item href="/logout"><RiLockLine/> ออกจากระบบ</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
