import {useEffect, useRef, useState} from "react";
import {Editor} from '@tinymce/tinymce-react';
import {FiTrash2} from "react-icons/fi";
import axios from "axios";
import {updatePostImage} from "../../common/api/postApi";
// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
// importing the plugin js.


const TinyMCE = ({content = '', setContent, keyIndex, handleRemoveContentRow}) => {
    const editorRef = useRef(null);
    const [editorContent, setEditorContent] = useState()

    useEffect(() => {
        setEditorContent(content)
    }, [content])

    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    const onChange = () => {
        setContent(keyIndex, editorRef.current.getContent())
        setEditorContent(editorRef.current.getContent())
        // console.log(editorRef.current.getContent());
    }

    const handleImageUpload = (file, callback) => {
        const formData = new FormData();
        formData.append("image", file.blob());
        updatePostImage(formData).then((response) => {
            callback(`${window?.appConfig?.API_ENDPOINT || process.env.REACT_APP_API_BASE}/media/${response.data.message}`);
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <>
            {/*<textarea className="text-editor"></textarea>*/}
            <div className="editor">
                {typeof handleRemoveContentRow !== `undefined` && (
                    <div className="delete">
                        <button className="btn btn-danger" onClick={() => handleRemoveContentRow(keyIndex)}><FiTrash2/>
                        </button>
                    </div>
                )}
                <Editor
                    onInit={(evt, editor) => editorRef.current = editor}
                    initialValue={editorContent}
                    onEditorChange={() => onChange()}
                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                    init={{
                        height: 500,
                        menubar: true,
                        // plugins: "link image textpattern lists ",
                        toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | image | pageembed code preview | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        plugins: "link image textpattern lists code",

                        images_upload_url: true,
                        images_upload_handler: handleImageUpload,
                    }}
                />
                {/*<button onClick={log}>Log editor content</button>*/}
            </div>
        </>
    );
}

export default TinyMCE
