import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {addContactApi, changeStatusContactApi, fetchContactApi, getContactApi} from "../../../common/api/contactApi";
import {notificationErrorCustomMessage} from "../../../common/config/message";

export const fetchContactAsync = createAsyncThunk(
    "doctor/fetchContactAsync",
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchContactApi(filter))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export const getContactAsync = createAsyncThunk(
    "doctor/getContactAsync",
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getContactApi(id))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)
export const addContactAsync = createAsyncThunk(
    "doctor/addContactAsync",
    async ({data}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, addContactApi(data))
            if (response.status === 200) {
                return true
            }
        } catch (e) {
            console.log(e)
        }
        return false
    }
)
export const changeStatusContactAsync = createAsyncThunk(
    "doctor/changeContactAsync",
    async ({id, filter, data}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, changeStatusContactApi(id))
            if (response.status === 200) {
                baseThunk.dispatch(fetchContactAsync(filter))
            }
        } catch (e) {
            console.log(e)
        }
        return false
    }
)
