import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setisAuthenticated, tooManyLogin} from "../../features/reducer/user/userSlice";
import {Alert, TextField} from "@mui/material";
import {loginAsyncUser} from "../../features/reducer/user/userThunk";
import {redirect, setRedirect} from "../../features/reducer/utility";
import {useNavigate} from "react-router-dom";
import {notificationErrorCustomMessage} from "../../common/config/message";

function Login() {
    const dispatch = useDispatch()
    const tooManyLogined = useSelector(tooManyLogin)
    const isRedirect = useSelector(redirect)
    const [data, setData] = useState({
        username: ``, password: ``
    })
    let navigate = useNavigate();


    useEffect(() => {
        if (isRedirect !== false && isRedirect !== '') {
            dispatch(setisAuthenticated(true))
            navigate(isRedirect)
            dispatch(setRedirect(false))
        }
    }, [isRedirect])

    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (data.username == `` || data.password == ``) {
            notificationErrorCustomMessage('กรุณากรอกข้อมูลให้ครบ', 'info')
            return
        }
        dispatch(loginAsyncUser(data))
    }


    return (
        <div className="login-section">
            <div className="container">
                <div className="login-box">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="wrap d-md-flex">
                                <div className="login-wrap w-100 p-4 p-lg-5 order-0">
                                    <div className="d-flex">
                                        <div className="w-100 mb-4">
                                            <img src="/assets/images/logo.svg" alt=""/>
                                        </div>
                                    </div>
                                    <form onSubmit={e => handleSubmit(e)} className="signin-form">

                                        {tooManyLogined && (
                                            <Alert severity="error" className="mb-4">เข้าสู่ระบบผิดพลาดหลายครั้ง กรุณารอ 5 นาที</Alert>
                                        )}
                                        <div className="mb-3">
                                            <TextField fullWidth label="ชื่อเข้าใช้งาน" name="username" onChange={e => handleInput(e)} size="small" variant="outlined"/>
                                            {/*<label className="label" htmlFor="name">Username</label>*/}
                                            {/*<input type="text" className="form-control" name="username" onChange={e => handleInput(e)} autoComplete="new-password" placeholder="ชื่อเข้าใช้งาน" required=""/>*/}
                                        </div>
                                        <div className="form-group mb-3">
                                            <TextField type="password" fullWidth label="รหัสผ่าน" name="password" onChange={e => handleInput(e)} size="small" variant="outlined"/>
                                            {/*<label className="label" htmlFor="password">Password</label>*/}
                                            {/*<input type="password" className="form-control" name="password" onChange={e => handleInput(e)} autoComplete="new-password" placeholder="รหัสผ่าน" required=""/>*/}
                                        </div>
                                        <div className="form-group">
                                            <button className="btn btn-primary btn-block px-3">Sign In</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="text-wrap w-100 p-4 p-lg-5 text-center d-flex align-items-center order-1">
                                    <div className="text w-100">
                                        <h2>Welcome to login</h2>
                                        <p>Don't have an account? Please contact to</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
