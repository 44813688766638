import {createSlice} from "@reduxjs/toolkit";
import {createPaymentAsync, getOrderAsync, getOrdersAsync} from "./orderThunk";

const orderSlice = createSlice({
    name: "order",
    initialState: {
        count: 0,
        items: [],
        order: {},
        orders: {},
    },
    reducers: {
        setOrderItems: (state, {payload}) => {
            return {...state, items: payload}
        },
        setOrderCount: (state, {payload}) => {
            return {...state, count: payload}
        },
    },
    extraReducers: {
        [getOrderAsync.fulfilled]: (state, {payload}) => {
            return {...state, order: payload}
        },
        [getOrdersAsync.fulfilled]: (state, {payload}) => {
            return {...state, orders: payload}
        },
    }
})

export const orderSelector = state => state.order

export const {
    setOrderItems, setOrderCount
} = orderSlice.actions
export default orderSlice.reducer
