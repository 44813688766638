import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {
    deletePostApi,
    fetchPostApi,
    getPostApi,
    newPostApi,
    sortingPostApi,
    updatePostApi, updatePostLangApi
} from "../../../common/api/postApi";
import {setModal, setRedirect} from "../utility";
import {notificationErrorCustomMessage, notificationSuccessMessage} from "../../../common/config/message";
import {resetPost} from "./postSlice";


export const fetchPostAsync = createAsyncThunk(
    "post/fetchPostAsync",
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchPostApi(filter))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)

export const getPostAsync = createAsyncThunk(
    "post/getPostAsync",
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getPostApi(id))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
        }
    }
)


export const createPostAsync = createAsyncThunk(
    "post/getPostAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, newPostApi(data.form))
            if (response.status === 200) {
                baseThunk.dispatch(resetPost())
                baseThunk.dispatch(setRedirect(`/${data.postType}`))
                notificationSuccessMessage(`บันทึกข้อมูล สำเร็จ`)
                return response.data
            }
        } catch (e) {
        }
    }
)

export const updatePostAsync = createAsyncThunk(
    "post/updatePostAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updatePostApi(data.id, data.form))
            if (response.status === 200) {
                // baseThunk.dispatch(resetPost())
                baseThunk.dispatch(getPostAsync(data.id))
                notificationSuccessMessage(`บันทึกข้อมูล สำเร็จ`)
                return response.data
            } else {

            }
        } catch (e) {
            if (e?.payload?.response?.status === 409) {
                notificationErrorCustomMessage(`ชื่อ Slug ถูกใช้งานแล้ว`, `warning`)
            }
        }
    }
)

export const updatePostLangAsync = createAsyncThunk(
    "post/updatePostLangAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updatePostLangApi(data.id, data.lang, data.form))
            if (response.status === 200) {
                baseThunk.dispatch(getPostAsync(data.id))
                notificationSuccessMessage(`บันทึกข้อมูล สำเร็จ`)
                return response.data
            } else {

            }
        } catch (e) {
            if (e?.payload?.response?.status === 409) {
                notificationErrorCustomMessage(`ชื่อ Slug ถูกใช้งานแล้ว`, `warning`)
            }
        }
    }
)

export const deletePostAsync = createAsyncThunk(
    "post/getPostAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, deletePostApi(data.id))
            if (response.status === 200) {
                baseThunk.dispatch(fetchPostAsync(data.search))
                notificationSuccessMessage(`ลบข้อมูล สำเร็จ`)
                return response.data
            }
        } catch (e) {
        }
    }
)
export const sortingPostAsync = createAsyncThunk(
    "post/sortingPostAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, sortingPostApi({sorting: data.sort}))
            if (response.status === 200) {
                baseThunk.dispatch(fetchPostAsync(data.search))
                baseThunk.dispatch(setModal(false))
                notificationSuccessMessage(`จัดเรียงข้อมูล สำเร็จ`)
                return response.data
            }
        } catch (e) {
        }
    }
)
