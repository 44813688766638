export const MonthTH = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
]

export const Roles = [
    {key: `admin`, label: `ผู้ดูแลระบบ`},
    {key: `emp`, label: `พนักงาน`},
]


export const Provinces = [
    {"id": "1", "name": "กรุงเทพมหานคร", "nameEn": "Bangkok"},
    {"id": "2", "name": "สมุทรปราการ", "nameEn": "Samut Prakan"},
    {"id": "3", "name": "นนทบุรี", "nameEn": "Nonthaburi"},
    {"id": "4", "name": "ปทุมธานี", "nameEn": "Pathum Thani"},
    {"id": "5", "name": "พระนครศรีอยุธยา", "nameEn": "Phra Nakhon Si Ayutthaya"},
    {"id": "6", "name": "อ่างทอง", "nameEn": "Ang Thong"},
    {"id": "7", "name": "ลพบุรี", "nameEn": "Loburi"},
    {"id": "8", "name": "สิงห์บุรี", "nameEn": "Sing Buri"},
    {"id": "9", "name": "ชัยนาท", "nameEn": "Chai Nat"},
    {"id": "10", "name": "สระบุรี", "nameEn": "Saraburi"},
    {"id": "11", "name": "ชลบุรี", "nameEn": "Chon Buri"},
    {"id": "12", "name": "ระยอง", "nameEn": "Rayong"},
    {"id": "13", "name": "จันทบุรี", "nameEn": "Chanthaburi"},
    {"id": "14", "name": "ตราด", "nameEn": "Trat"},
    {"id": "15", "name": "ฉะเชิงเทรา", "nameEn": "Chachoengsao"},
    {"id": "16", "name": "ปราจีนบุรี", "nameEn": "Prachin Buri"},
    {"id": "17", "name": "นครนายก", "nameEn": "Nakhon Nayok"},
    {"id": "18", "name": "สระแก้ว", "nameEn": "Sa Kaeo"},
    {"id": "19", "name": "นครราชสีมา", "nameEn": "Nakhon Ratchasima"},
    {"id": "20", "name": "บุรีรัมย์", "nameEn": "Buri Ram"},
    {"id": "21", "name": "สุรินทร์", "nameEn": "Surin"},
    {"id": "22", "name": "ศรีสะเกษ", "nameEn": "Si Sa Ket"},
    {"id": "23", "name": "อุบลราชธานี", "nameEn": "Ubon Ratchathani"},
    {"id": "24", "name": "ยโสธร", "nameEn": "Yasothon"},
    {"id": "25", "name": "ชัยภูมิ", "nameEn": "Chaiyaphum"},
    {"id": "26", "name": "อำนาจเจริญ", "nameEn": "Amnat Charoen"},
    {"id": "27", "name": "หนองบัวลำภู", "nameEn": "Nong Bua Lam Phu"},
    {"id": "28", "name": "ขอนแก่น", "nameEn": "Khon Kaen"},
    {"id": "29", "name": "อุดรธานี", "nameEn": "Udon Thani"},
    {"id": "30", "name": "เลย", "nameEn": "Loei"},
    {"id": "31", "name": "หนองคาย", "nameEn": "Nong Khai"},
    {"id": "32", "name": "มหาสารคาม", "nameEn": "Maha Sarakham"},
    {"id": "33", "name": "ร้อยเอ็ด", "nameEn": "Roi Et"},
    {"id": "34", "name": "กาฬสินธุ์", "nameEn": "Kalasin"},
    {"id": "35", "name": "สกลนคร", "nameEn": "Sakon Nakhon"},
    {"id": "36", "name": "นครพนม", "nameEn": "Nakhon Phanom"},
    {"id": "37", "name": "มุกดาหาร", "nameEn": "Mukdahan"},
    {"id": "38", "name": "เชียงใหม่", "nameEn": "Chiang Mai"},
    {"id": "39", "name": "ลำพูน", "nameEn": "Lamphun"},
    {"id": "40", "name": "ลำปาง", "nameEn": "Lampang"},
    {"id": "41", "name": "อุตรดิตถ์", "nameEn": "Uttaradit"},
    {"id": "42", "name": "แพร่", "nameEn": "Phrae"},
    {"id": "43", "name": "น่าน", "nameEn": "Nan"},
    {"id": "44", "name": "พะเยา", "nameEn": "Phayao"},
    {"id": "45", "name": "เชียงราย", "nameEn": "Chiang Rai"},
    {"id": "46", "name": "แม่ฮ่องสอน", "nameEn": "Mae Hong Son"},
    {"id": "47", "name": "นครสวรรค์", "nameEn": "Nakhon Sawan"},
    {"id": "48", "name": "อุทัยธานี", "nameEn": "Uthai Thani"},
    {"id": "49", "name": "กำแพงเพชร", "nameEn": "Kamphaeng Phet"},
    {"id": "50", "name": "ตาก", "nameEn": "Tak"},
    {"id": "51", "name": "สุโขทัย", "nameEn": "Sukhothai"},
    {"id": "52", "name": "พิษณุโลก", "nameEn": "Phitsanulok"},
    {"id": "53", "name": "พิจิตร", "nameEn": "Phichit"},
    {"id": "54", "name": "เพชรบูรณ์", "nameEn": "Phetchabun"},
    {"id": "55", "name": "ราชบุรี", "nameEn": "Ratchaburi"},
    {"id": "56", "name": "กาญจนบุรี", "nameEn": "Kanchanaburi"},
    {"id": "57", "name": "สุพรรณบุรี", "nameEn": "Suphan Buri"},
    {"id": "58", "name": "นครปฐม", "nameEn": "Nakhon Pathom"},
    {"id": "59", "name": "สมุทรสาคร", "nameEn": "Samut Sakhon"},
    {"id": "60", "name": "สมุทรสงคราม", "nameEn": "Samut Songkhram"},
    {"id": "61", "name": "เพชรบุรี", "nameEn": "Phetchaburi"},
    {"id": "62", "name": "ประจวบคีรีขันธ์", "nameEn": "Prachuap Khiri Khan"},
    {"id": "63", "name": "นครศรีธรรมราช", "nameEn": "Nakhon Si Thammarat"},
    {"id": "64", "name": "กระบี่", "nameEn": "Krabi"},
    {"id": "65", "name": "พังงา", "nameEn": "Phangnga"},
    {"id": "66", "name": "ภูเก็ต", "nameEn": "Phuket"},
    {"id": "67", "name": "สุราษฎร์ธานี", "nameEn": "Surat Thani"},
    {"id": "68", "name": "ระนอง", "nameEn": "Ranong"},
    {"id": "69", "name": "ชุมพร", "nameEn": "Chumphon"},
    {"id": "70", "name": "สงขลา", "nameEn": "Songkhla"},
    {"id": "71", "name": "สตูล", "nameEn": "Satun"},
    {"id": "72", "name": "ตรัง", "nameEn": "Trang"},
    {"id": "73", "name": "พัทลุง", "nameEn": "Phatthalung"},
    {"id": "74", "name": "ปัตตานี", "nameEn": "Pattani"},
    {"id": "75", "name": "ยะลา", "nameEn": "Yala"},
    {"id": "76", "name": "นราธิวาส", "nameEn": "Narathiwat"},
    {"id": "77", "name": "บึงกาฬ", "nameEn": "buogkan"}
]


export const Languages = [
    {code: 'th', name: `ไทย`},
    {code: 'en', name: `อังกฤษ`},
    {code: 'zh', name: `จีน`},
    // {code: 'jp', name: `ญี่ปุ่น`},
];


export const MAX_UPLOAD_FILE_SIZE = 2 * 1024;
export const MAX_UPLOAD_FILE_SIZE_MESSAGE = `ไฟล์มีขนาดเกินกว่าที่กำหนด กรุณาเลือกไฟล์ที่มีขนาดไม่เกิน ${MAX_UPLOAD_FILE_SIZE / 1024} MB`;
