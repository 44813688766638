import {httpObjQueryString} from "../config/httpClient";
import {setDataParams, SetGateWay} from "./gateway";

const {httpGateWay, httpGateWayForm} = SetGateWay()
const httpClientForm = httpGateWayForm
export const fetchPostApi = filter => {
    return httpGateWay.get(`/post/lists?${httpObjQueryString(filter)}`)
}

export const newPostApi = (data) => {
    return httpClientForm.post(`/post/create`, data)
}

export const updatePostApi = (id, data) => {
    return httpClientForm.put(`/post/${id}`, data)
}
export const updatePostLangApi = (id, lang, data) => {
    return httpClientForm.put(`/post/language/${lang}/${id}`, data)
}

export const updatePostImage = (data) => {
    return httpClientForm.post(`/post/upload`, data)
}

export const deletePostApi = (id) => {
    return httpGateWay.delete(`/post/${id}`)
}

export const getPostApi = (id) => {
    return httpGateWay.get(`/post/${id}/view`)
}

export const sortingPostApi = (data) => {
    return httpGateWay.put(`/post/sorting`, data)
}


export const getFrontUrl = () => {
    return httpGateWay.get(`/post/frontUrl`)
}
