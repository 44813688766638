import XLSX from "sheetjs-style"
import * as FileSaver from "file-saver"
import {FiDownload} from "react-icons/fi";
import {useEffect, useState} from "react";

const ExcelExport = ({excelData, fileName, handleLoadData}) => {

    const [data, setData] = useState()

    const fileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8`
    const fileExtension = `.xlsx`

    useEffect(() => {
        setData(excelData)
    }, [excelData])

    const exportToExcel = async (dt) => {
        const ws = XLSX.utils.json_to_sheet(dt)
        const wb = {Sheets: {data: ws}, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const dataExcel = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(dataExcel, fileName + fileExtension)
    }

    return (
        <><button className="btn btn-success" onClick={() => handleLoadData(exportToExcel)}><FiDownload/> Export Excel</button></>
    )

}

export default ExcelExport
