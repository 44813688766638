import httpClient, {httpClientForm, httpObjQueryString} from "../config/httpClient";


export const fetchAppointmentApi = filter => {
    return httpClient.get(`/appointment/lists?${httpObjQueryString(filter)}`)
}

export const addAppointmentApi = data => {
    return httpClientForm.post(`/appointment/new`, data)
}

export const getAppointmentApi = id => {
    return httpClient.get(`/appointment/${id}`)
}


export const changeStatusAppointmentApi = (id, data) => {
    return httpClient.put(`/appointment/change/${id}`, data)
}
