import {setDataParams, SetGateWay} from "./gateway";

const {httpGateWay} = SetGateWay()
const httpClient = httpGateWay

export const getAllSettingApi = (keys) => {
    return httpClient.post(`/option/all`, {
        name: keys
    })
}


export const addSettingApi = (data) => {
    return httpClient.post(`/option/create`, setDataParams(data))
}
