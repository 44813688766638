import {createSlice} from "@reduxjs/toolkit";
import {fetchPostAsync, getPostAsync} from "./postThunk";

const postSlice = createSlice({
    name: "post",
    initialState: {
        posts: {
            data: [],
        },
        post: null,
        postType: {
            clinic: "ศูนย์รักษาโรค",
            technology: "เทคโนโลยีของเรา",
            membercard: "บัตรสมาชิก",
            packages: "แพ็คเกจและโปรโมชั่น",
            news: "ข่าวสารและกิจกรรม",
            activity: "กิจกรรมเพื่อสังคม",
            impression: "ความประทับใจ",
            research: "งานวิจัย",
            health: "บทความที่เกี่ยวข้อง & CV Doctor",
            healthy: "บทความทสุขภาพ",
            rjractivity: "กิจกรรม",
            room: "ห้องพักผู้ป่วย",
            slider: "ภาพสไลด์",
            medical: "การแพทย์",
            nurse: "พยาบาล",
            office: "สำนักงานบริหาร",
            popup: "Popup วันสำคัญ",
            "medical-support": "สนับสนุนทางการแพทย์",
        },
        setting: {
            editor: [
                `clinic`,
                `technology`,
                `membercard`,
                `packages`,
                `news`,
                `activity`,
                `impression`,
                `research`,
                `health`,
                `healthy`,
                `rjractivity`,
                `room`,
                `medical`,
                `nurse`,
                `office`,
            ],
        },
        packageCluster: [
            "Health Promotion Clinic",
            "ER , EMS, Telemedicine",
            "Heart Center",
            "OB-GYN Clinic",
            "Mammogram Clinic",
            "Well Baby Clinic",
            "Pediatric Clinic",
            "Internal Medicine Clinic",
            "Geriatric Clinic",
            "Orthopedic & Spine Clinic",
            "Gen Surgery & Urology Clinic",
            "GI &Scope Unit",
            "Neuro & Mental Health Clinic",
            "Oncology & Hematology Clinic",
            "Traditional Chinese Medicine Clinic",
            "Rehabilitation",
            "DM & Endocrine Clinic",
            "E.N.T. Clinic",
            "Eye Clinic",
            "Skin & Beauty",
            "Dental Clinic",
            "Hemodialysis",
            "Others",
        ],
    },
    reducers: {
        resetPost: (state, {payload}) => {
            return {...state, post: ``}
        }
    },
    extraReducers: {
        [fetchPostAsync.fulfilled]: (state, action) => {
            return {
                ...state, posts: {
                    ...action.payload,
                    data: action.payload.data.map(v => {
                        if (typeof v?.packageCluster !== `undefined` && v?.packageCluster !== ``) {
                            console.log(v?.packageCluster)
                            let packages = state.packageCluster.find((value, index) => index == v?.packageCluster)
                            console.log(`${v?.title} ${packages}`)
                            return {...v, packageClusterName: packages || ``}
                        }
                        return v
                    })
                }
            }
        },
        [getPostAsync.fulfilled]: (state, action) => {
            return {...state, post: action.payload}
        },
    },
})

export const postSelector = state => state.post
export const postSelectorLists = state => state.post.posts
export const postSelectorPostType = state => state.post.postType
export const postSelectorPost = state => state.post.post
export const postSelectorPackageClusters = state => state.post.packageCluster
export const postSetting = state => state.post.setting
export const {
    resetPost
} = postSlice.actions
export default postSlice.reducer
