import {FiUser} from "react-icons/fi";
import React from "react";

const UserType = ({type}) => {
    switch (type) {
        case `admin`:
            return (
                <span className="text-danger"><FiUser/> ผู้ดูแลระบบ</span>
            )
        case `emp`:
            return (
                <span className="text-success"><FiUser/> พนักงาน</span>
            )
        case `member`:
            return (
                <span className="text-primary"><FiUser/> สมาชิก</span>
            )
    }
    return (
        <>
        </>
    )
}

export default UserType
