import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modal, setModal} from "../../features/reducer/utility";

const MyModal = ({children, title, size = `lg`}) => {
    const dispatch = useDispatch()
    const isShow = useSelector(modal)
    const handleModalClose = () => {
        dispatch(setModal(false))
    }
    return (
        <Modal size={typeof size !== `undefined` ? size : `lg`} show={isShow} onHide={handleModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default MyModal
