import {getSubDomain} from "../functions/usage";
import httpClient, {httpClientForm} from "../config/httpClient";

export const SetGateWay = () => {
    const subdomain = ''
    const baseUrl = `${window?.appConfig?.API_ENDPOINT || process.env.REACT_APP_API_BASE}${subdomain}`
    httpClient.defaults.baseURL = baseUrl
    httpClientForm.defaults.baseURL = baseUrl

    return {
        httpGateWay: httpClient,
        httpGateWayForm: httpClientForm
    }
}

export const checkSubDomain = (sub = '') => {
    let domain = sub
    if (domain === ``) {
        domain = getSubDomain()
    }
    if (typeof domain === `undefined` || domain === `test` || domain === `` || domain === `localhost:3000` || domain.includes(`localhost`) || domain.includes(`127.0.0.1`)) {
        domain = `main`
    }

    return domain
}

export const setDataParams = data => {
    return {...data}
}
