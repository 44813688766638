import {useEffect, useState} from "react";
import moment from "moment";

const DateThai = ({date = ``, dateInline = ``, showTime = false, digit = false}) => {
    const months = [
        {n: 1, name: `มกราคม`},
        {n: 2, name: `กุมภาพันธ์`},
        {n: 3, name: `มีนาคม`},
        {n: 4, name: `เมษายน`},
        {n: 5, name: `พฤษภาคม`},
        {n: 6, name: `มิถุนายน`},
        {n: 7, name: `กรกฏาคม`},
        {n: 8, name: `สิงหาคม`},
        {n: 9, name: `กันยายน`},
        {n: 10, name: `ตุลาคม`},
        {n: 11, name: `พฤศจิกายน`},
        {n: 12, name: `ธันวาคม`},
    ]
    const [data, setData] = useState()

    useEffect(() => {

        if (date !== ``) {
            let text = ``
            const dT = new Date(date)
            if (isNaN(dT.getTime())) {
                text = `-`
            } else {

                const d = moment(date)
                const m = parseInt(parseInt(d.month()) + 1)
                const month = months.find(v => v.n == m)

                text = `${addLeadingZeros(d.date(), 2)} ${month?.name || ``} ${parseInt(d.year()) + 543}`
                if (showTime) {
                    text += ` ${addLeadingZeros(d.hour(), 2)}:${addLeadingZeros(d.minute(), 2)} น.`
                }

                if (digit) {
                    text = `${d.year()}-${addLeadingZeros(d.month()+1, 2)}-${addLeadingZeros(d.date(), 2)}`
                    if (showTime) {
                        text += ` ${addLeadingZeros(d.hour(), 2)}:${addLeadingZeros(d.minute(), 2)}`
                    }
                }
            }
            // console.log(text)

            setData(text)
        }
    }, [date])

    useEffect(() => {
        if (dateInline !== ``) {
            const y = dateInline.substring(0, 4)
            const m = dateInline.substring(4, 6)
            const d = dateInline.substring(6, 8)
            const h = dateInline.substring(8, 10)
            const mi = dateInline.substring(10, 12)
            const s = dateInline.substring(12, 14)
            // const d = moment(date)
            const month = months.find(v => v.n == m)

            let text = `${addLeadingZeros(d, 2)} ${month?.name || ``} ${parseInt(y) + 543}`
            if (showTime) {
                text += ` ${addLeadingZeros(h, 2)}:${addLeadingZeros(mi, 2)} น.`
            }

            if (digit) {
                text = `${y}-${m}-${d}`
                if (showTime) {
                    text += ` ${addLeadingZeros(h, 2)}:${addLeadingZeros(mi, 2)}`
                }
            }
            // alert(text)
            setData(text)
        }
    }, [dateInline])

    function addLeadingZeros(num, totalLength) {
        return String(num).padStart(totalLength, '0');
    }

    return (
        <>{data}</>
    )

}

export default DateThai
