import * as React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

import Login from "./components/authentication/Login";
import Admin from "./components/admin/Admin";

import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-image-lightbox/style.css';
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import './App.css';
import './AppDark.css';
import User from "./components/admin/user/User";
import UserCreate from "./components/admin/user/UserCreate";


import Category from "./components/admin/category/Category";
import Logout from "./components/authentication/Logout";
import {ReactNotifications} from "react-notifications-component";
// import PostCreate from "./components/admin/post/PostCreate";
import Doctor from "./components/admin/doctor/Doctor";
import DoctorCreate from "./components/admin/doctor/DoctorCreate";
import Appointment from "./components/admin/appointment/Appointment";
import Order from "./components/admin/order/Order";
import DoctorEditLanguage from "./components/admin/doctor/DoctorEditLanguage";
// import PostEditLanguage from "./components/admin/post/PostEditLanguage";
// import Post from "./components/admin/post/Post";
// const Product = React.lazy(() => import('./components/admin/product/Product'));
// const Category = React.lazy(() => import('./components/admin/category/Category'));
// const ExpenseFacebook = React.lazy(() => import('./components/admin/expense/Expense'));
// const ExpenseShipping = React.lazy(() => import('./components/admin/expense/Expense'));
//
// const OrderSend = React.lazy(() => import('./components/admin/order/Order'));
const Post = React.lazy(() => import('./components/admin/post/Post'));
const PostCreate = React.lazy(() => import('./components/admin/post/PostCreate'));
const PostEditLanguage = React.lazy(() => import('./components/admin/post/PostEditLanguage'));
const Contact = React.lazy(() => import('./components/admin/contact/Contact'));
const Appeal = React.lazy(() => import('./components/admin/contact/Contact'));


function App() {
    return (
        <>
            <Router>
                <React.Suspense fallback={<p id="loading" className="d-none"> Loading...</p>}>
                    <Routes>
                        <Route exact={true} path="/login" element={<Login/>}/>
                        <Route exact={true} path="/logout" element={<Logout/>}/>
                        <Route path="/" element={<Admin/>}>
                            <Route path="/" element={<User role="admin-emp"/>}/>

                            <Route path="/order" element={<Order/>}/>

                            <Route path="/member" element={<User role="member"/>}/>
                            <Route path="/user" element={<User role="admin-emp"/>}/>
                            <Route path="/user/create" element={<UserCreate/>}/>
                            <Route path="/user/:id/edit" element={<UserCreate/>}/>


                            <Route path="/appointment" element={<Appointment/>}/>
                            <Route path="/contact" element={
                                <Contact title={`ข้อมูลการติดต่อ`} contactType={`contact`}/>}/>
                            <Route path="/appeal" element={
                                <Appeal title={`ข้อมูลการร้องเรียน`} contactType={`appeal`}/>}/>

                            <Route path="/doctor/create" element={<DoctorCreate/>}/>
                            <Route path="/doctor/:id/edit" element={<DoctorCreate/>}/>
                            <Route path="/doctor" element={<Doctor/>}/>

                            <Route path="/personal/:type/create" element={<DoctorCreate/>}/>
                            <Route path="/personal/:type/:id/edit" element={<DoctorCreate/>}/>
                            <Route path="/personal/:lang/:type/:id/edit" element={<DoctorEditLanguage/>}/>
                            <Route path="/personal/:type" element={<Doctor/>}/>

                            <Route path="/:postType/create" element={<PostCreate/>}/>
                            <Route path="/:postType/:id/edit" element={<PostCreate/>}/>
                            <Route path="/:postType/:lang/:id/edit" element={<PostEditLanguage/>}/>
                            <Route path="/:postType" element={<Post/>}/>

                        </Route>
                    </Routes>
                </React.Suspense>
            </Router>
            <ReactNotifications/>
        </>
    );
}

export default App;
