import {createSlice} from "@reduxjs/toolkit";
import {
    deleteAsyncDoctor,
    fetchAsyncDoctors,
    getAsyncDoctor,
    getAsyncDoctorClinics,
    getAsyncDoctorSpecialties
} from "./doctorThunk";

const initialState = {
    doctors: {
        data: [],
    },
    doctor: null,
    specialties: [],
    clinics: [],
    submited: false,
    redirectUrl: null,
    doctorType: {
        director: `คณะกรรมการบริหาร`,
        board: `คณะกรรมการบริษัท`,
        manager: `ผู้บริหารโรงพยาบาล`,
        doctor: `แพทย์`,
    }
}


const doctorSlice = createSlice({
    name: `doctor`, initialState: initialState, reducers: {
        setDoctor: (state, {payload}) => {
            return {...state, doctor: payload}
        },
        resetDoctor: (state, {payload}) => {
            return {...state, doctor: ``}
        }
    },
    extraReducers: {


        [fetchAsyncDoctors.fulfilled]: (state, {payload}) => {
            return {...state, doctors: payload, redirectUrl: null}
        },


        [getAsyncDoctor.fulfilled]: (state, {payload}) => {
            // console.log(payload)
            return {...state, doctor: payload}
        },

        [getAsyncDoctorSpecialties.fulfilled]: (state, {payload}) => {
            let data = []
            Object.keys(payload).map(key => {
                const item = payload[key]
                if (typeof item?.name !== `undefined`) {
                    let subs = []
                    Object.keys(item?.sub).map(keyItem => {
                        subs.push({
                            code: keyItem,
                            name: item?.sub[keyItem],
                        })
                    })
                    data.push({
                        name: item?.name,
                        code: key,
                        sub: subs,
                    })
                } else {
                    data.push({
                        name: item,
                        code: key,
                        sub: [],
                    })
                }
            })
            return {...state, specialties: data}
        },

        [getAsyncDoctorClinics.fulfilled]: (state, {payload}) => {
            let data = []
            Object.keys(payload).map(key => {
                const item = payload[key]
                data.push({
                    name: item,
                    code: key,
                })
            })
            return {...state, clinics: data}
        },

        [deleteAsyncDoctor.fulfilled]: (state, {payload}) => {
            const id = payload
            return {
                ...state, doctors: {
                    ...state.doctors, data: state.doctors.data.filter(prop => prop.id !== id),
                }, redirectUrl: null
            }
        },


    },
})


export const getDoctors = (state) => state.doctors.doctors
export const getDoctor = (state) => state.doctors.doctor
export const getSpecialties = (state) => state.doctors.specialties
export const getClinics = (state) => state.doctors.clinics
export const getDoctorType = (state) => state.doctors.doctorType

export const {setDoctor, resetDoctor} = doctorSlice.actions
export default doctorSlice.reducer
