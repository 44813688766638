import {decryptJson, encryptJson} from "../common/functions/encrypt";

const localStorageService = (() => {
    let service

    function getService() {
        if (!service) {
            service = this
            return service
        }

        return service
    }

    function setToken(token) {
        localStorage.setItem(`access_token`, token.accessToken)
        localStorage.setItem(`refresh_token`, token.refreshToken)
        localStorage.setItem(`authentication`, "true")
    }

    function setMe(data) {
        localStorage.setItem(`me`, JSON.stringify(data))
    }


    function setConfig(data) {
        localStorage.setItem(`domain_config`, encryptJson(data))
    }

    function getAccessToken() {
        return localStorage.getItem(`access_token`)
    }

    function getKAAccessToken() {
        return localStorage.getItem(`access_ka_token`)
    }

    function getRefreshToken() {
        return localStorage.getItem(`refresh_token`)
    }

    function getConfig() {
        return decryptJson(localStorage.getItem(`domain_config`))
        // return JSON.parse(conf)
        // if (typeof conf != `undefined` && conf !== null && conf !== ``)
        //
        //
        //     return null
    }

    function isAuthentication() {
        return localStorage.getItem(`authentication`) === "true"
    }

    function getMe() {
        const me = localStorage.getItem(`me`)
        if (typeof me != `undefined`)
            return JSON.parse(me)

        return null
    }

    function forgetToken() {
        localStorage.removeItem(`access_token`)
        localStorage.removeItem(`refresh_token`)
        localStorage.removeItem(`me`)
        localStorage.removeItem(`authentication`)
        localStorage.removeItem(`access_ka_token`)
        localStorage.removeItem(`domain_config`)
    }

    function forgetKAToken() {
        localStorage.removeItem(`access_ka_token`)
    }

    return {
        getMe: getMe,
        setMe: setMe,
        getService: getService,
        setToken: setToken,
        getKAAccessToken: getKAAccessToken,
        getAccessToken: getAccessToken,
        getRefreshToken: getRefreshToken,
        forgetToken: forgetToken,
        forgetKAToken: forgetKAToken,
        isAuthentication: isAuthentication,
        getConfig: getConfig,
        setConfig: setConfig,

    }


})()

export default localStorageService
