import React, {useEffect, useState} from "react";
import {imageSrc} from "../../common/functions/usage";

const ImageView = ({imageUrl, name = '', isDefault = false, width = ``}) => {
    const [image, setImage] = useState("")
    const [exist, setExist] = useState(false)
    useEffect(() => {
        const url = imageSrc(imageUrl)
        setImage(url)
        imageExists(url)
    }, [imageUrl])

    const imageExists = url => {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            setExist(true);
        };
        img.onerror = function () {
            setExist(false);
        };
    }
    return (
        <>
            {isDefault ? (
                <img src={image} loading="lazy" width={width} alt=""/>
            ) : (
                <>
                    <div className="d-lg-block d-none">
                        <div className="img-card-placeholder img-card-s1 img-rounded ">
                            <div className="image" style={{backgroundImage: `url(${image})`}}>
                                {!exist && (
                                    <span>{name?.substring(0, 1)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="d-lg-none d-inline-flex justify-content-start">
                        <div className="img-card-placeholder img-card-s1 img-rounded">
                            <div className="image" style={{backgroundImage: `url(${image})`}}>
                                {!exist && (
                                    <span>{name?.substring(0, 1)}</span>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}
export default ImageView
