import {
    deleteDoctorApi,
    fetchDoctorsApi,
    getDoctorApi,
    getDoctorClinicApi,
    getDoctorSpecialtiesApi,
    newDoctorApi,
    sortingDoctorApi,
    updateDoctorApi,
    updateDoctorLangApi
} from "../../../common/api/doctorApi";
import {notificationSuccessMessage} from "../../../common/config/message";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {setModal, setRedirect} from "../utility";
import {resetPost} from "../post/postSlice";
import {fetchPostAsync} from "../post/postThunk";


export const fetchAsyncDoctors = createAsyncThunk(
    'doctors/fetchAsyncDoctors',
    async ({filter}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, fetchDoctorsApi(filter))
            return response.data
        } catch (e) {
        }
    }
)


export const getAsyncDoctor = createAsyncThunk(
    'doctors/getAsyncDoctor',
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getDoctorApi(id))
            return response.data
        } catch (e) {
        }
    }
)

export const getAsyncDoctorSpecialties = createAsyncThunk(
    'doctors/getAsyncDoctorSpecialties',
    async ({}, baseThunk) => {
        // alert(555)
        try {
            const response = await httpRequestFunc(baseThunk, getDoctorSpecialtiesApi())
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const getAsyncDoctorClinics = createAsyncThunk(
    'doctors/getAsyncDoctorClinics',
    async ({}, baseThunk) => {
        // alert(555)
        try {
            const response = await httpRequestFunc(baseThunk, getDoctorClinicApi())
            return response.data
        } catch (e) {
            console.log(e)
        }
    }
)

export const newAsyncDoctor = createAsyncThunk(
    'doctors/newAsyncDoctor',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, newDoctorApi(data.form))
            if (response.status === 200) {
                baseThunk.dispatch(resetPost())
                // baseThunk.dispatch(setRedirect(`/doctor`))
                baseThunk.dispatch(setRedirect(`/personal/${data?.type}`))
                notificationSuccessMessage(`บันทึกข้อมูล สำเร็จ`)
                return response.data
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const updateAsyncDoctor = createAsyncThunk(
    'doctors/updateAsyncDoctor',
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateDoctorApi(data.id, data.form))
            if (response.status === 200) {
                // baseThunk.dispatch(resetPost())
                // baseThunk.dispatch(setRedirect(`/personal/${data?.type}`))
                notificationSuccessMessage(`บันทึกข้อมูล สำเร็จ`)
                return response.data
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)

export const updateDoctorLangAsync = createAsyncThunk(
    "post/updateDoctorLangAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateDoctorLangApi(data.id, data.lang, data.form))
            if (response.status === 200) {
                // baseThunk.dispatch(getPostAsync(data.id))
                notificationSuccessMessage(`บันทึกข้อมูล สำเร็จ`)
                return response.data
            } else {

            }
        } catch (e) {

        }
    }
)


export const deleteAsyncDoctor = createAsyncThunk(
    'doctors/deleteAsyncDoctor',
    async ({id}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, deleteDoctorApi(id))
            if (response.status === 200) {
                notificationSuccessMessage(`ทำรายการสำเร็จ`)
                baseThunk.dispatch(fetchAsyncDoctors({
                    filter: {
                        page: 1,
                        pageSize: 15,
                    }
                }))
                return id
            }
        } catch (e) {
            // notificationErrorMessage(e)
        }
    }
)


export const sortingDoctorAsync = createAsyncThunk(
    "post/sortingDoctorAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, sortingDoctorApi({sorting: data.sort}))
            if (response.status === 200) {
                baseThunk.dispatch(fetchAsyncDoctors({filter: data.search}))
                baseThunk.dispatch(setModal(false))
                notificationSuccessMessage(`จัดเรียงข้อมูล สำเร็จ`)
                return response.data
            }
        } catch (e) {
        }
    }
)
