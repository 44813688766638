import {createSlice} from "@reduxjs/toolkit";
import {
    deleteAsyncUser,
    fetchAsyncUsers,
    getAsyncProfile,
    getAsyncUser,
    getDomainAsyncConfig,
    loginAsyncUser, newAsyncUser, updateAsyncUser
} from "./userThunk";
import localStorageService from "../../../services/localStorageService";
import {encryptJson} from "../../../common/functions/encrypt";

const localStorage = localStorageService.getService()
const initialState = {
    users: {
        data: [],
    },
    user: null,
    profile: null,
    tooManyLogin: false,
    isAuthentications: false,
    submited: false,
    redirectUrl: null,
    domainVerify: 0,
    config: null
}


const userSlice = createSlice({
    name: `user`, initialState: initialState, reducers: {
        setisAuthenticated: (state, {payload}) => {
            return {...state, isAuthentications: payload}
        }, setRedirect: (state, {payload}) => {
            return {...state, redirectUrl: payload}
        }, setUser: (state, {payload}) => {
            return {...state, user: payload}
        }, removeUser: (state, {payload}) => {
            const id = payload
            let users = JSON.parse(JSON.stringify(state.users));
            users.data = users.data.filter(prop => prop.username !== id)
            return {
                ...state, users: users
            }
        }, setDomainVerify: (state, {payload}) => {
            return {...state, domainVerify: payload}
        }, setTomanyLogin : (state, {payload}) => {
            return {
                ...state, tooManyLogin: true, isAuthentications: false, submited: false
            }
        },
    }, extraReducers: {

        [loginAsyncUser.pending]: (state) => {
            return {...state, submited: true}
        },
        [loginAsyncUser.rejected]: (state, {payload}) => {
            if (payload.message === `TOO_MANY_LOGIN`) {
                return {
                    ...state, tooManyLogin: true, isAuthentications: false, submited: false
                }
            }
            return {...state, submited: false, isAuthentications: false}
        }, [loginAsyncUser.fulfilled]: (state, {payload}) => {

            if (payload.message === `TOO_MANY_LOGIN`) {
                return {
                    ...state, tooManyLogin: true, isAuthentications: false, submited: false
                }
            }

            localStorage.setToken(payload)
            return {
                ...state, tooManyLogin: false, isAuthentications: true, submited: false
            }
        },


        //USERS
        [fetchAsyncUsers.fulfilled]: (state, {payload}) => {
            return {...state, users: payload, redirectUrl: null}
        },

        [getAsyncProfile.fulfilled]: (state, {payload}) => {
            return {...state, profile: payload, redirectUrl: null}
        },

        [getAsyncUser.fulfilled]: (state, {payload}) => {
            return {...state, user: payload, redirectUrl: null}
        },

        [deleteAsyncUser.fulfilled]: (state, {payload}) => {
            const id = payload
            return {
                ...state, users: {
                    ...state.users, data: state.users.data.filter(prop => prop.id !== id),
                }, redirectUrl: null
            }
        },
        [newAsyncUser.fulfilled]: (state, {payload}) => {
            let data = [...state.users.data]
            data.unshift(payload)
            return {
                ...state,
                users : {
                    ...state.users, data: data,
                }
            }
        },
        [updateAsyncUser.fulfilled]: (state, {payload}) => {

            return {
                ...state, users: {
                    ...state.users, data: state.users.data.map(prop => {
                        if (prop.id === payload.id) {
                            return payload
                        }
                        return prop
                    }),
                }, redirectUrl: null
            }
        },

        [getDomainAsyncConfig.fulfilled]: (state, {payload}) => {
            if (typeof payload !== `undefined`) {
                localStorage.setConfig(payload)
                return {
                    ...state, config: encryptJson(payload),
                }
            }
        },

    },
})


export const getUsers = (state) => state.users.users
export const getUser = (state) => state.users.user
export const getProfile = (state) => state.users.profile
export const tooManyLogin = (state) => state.users.tooManyLogin
export const isAuthentications = (state) => state.users.isAuthentications
export const domainVerify = (state) => state.users.domainVerify
export const userSubmited = (state) => state.users.submited
export const userState = (state) => state.users
export const userConfig = (state) => state.users.config

export const {removeUser, setisAuthenticated, setRedirect, setUser, setDomainVerify, setTomanyLogin} = userSlice.actions
export default userSlice.reducer
