import httpClient, {httpObjQueryString} from "../config/httpClient";

export const createPaymentOrder = (data) => {
    return httpClient.post(`/order/payment`, data)
}

export const updateOrderItemApi = (data) => {
    return httpClient.put(`/order/${data.order}`, data)
}

export const getOrder = (id) => {
    return httpClient.get(`/order/payment/${id}`)
}

export const getOrders = (filter) => {
    return httpClient.get(`/order?${httpObjQueryString(filter)}`)
}


export const extendOrders = (id) => {
    return httpClient.put(`/order/extend/${id}`)
}


export const cancelOrders = (id) => {
    return httpClient.put(`/order/cancel/${id}`)
}
