import httpClient, {httpClientForm, httpObjQueryString} from "../config/httpClient";


export const fetchContactApi = filter => {
    return httpClient.get(`/contacts?${httpObjQueryString(filter)}`)
}

export const addContactApi = data => {
    return httpClientForm.post(`/contact/new`, data)
}

export const getContactApi = id => {
    return httpClient.get(`/contacts/${id}`)
}


export const changeStatusContactApi = (id) => {
    return httpClient.put(`/contacts/${id}`)
}
