import {createAsyncThunk} from "@reduxjs/toolkit";
import {httpRequestFunc} from "../../../common/config/httpClient";
import {createPaymentOrder, extendOrders, getOrder, getOrders, updateOrderItemApi} from "../../../common/api/orderApi";
import {notificationErrorCustomMessage} from "../../../common/config/message";
import moment from "moment/moment";

export const createPaymentAsync = createAsyncThunk(
    "order/createPaymentAsync",
    async (data, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, createPaymentOrder(data))
            if (response.status === 200) {
                if (typeof response?.data?.webPaymentUrl !== `undefined` && response?.data?.webPaymentUrl !== ``) {
                    window.location.href = response?.data?.webPaymentUrl
                } else {
                    notificationErrorCustomMessage(`ไม่สามารถชำระเงินได้ กรุณาลองใหม่อีกครั้ง`)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export const getOrderAsync = createAsyncThunk(
    "order/getOrderAsync",
    async (id, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getOrder(id))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)

export const getOrdersAsync = createAsyncThunk(
    "order/getOrdersAsync",
    async (filter, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, getOrders(filter))
            if (response.status === 200) {
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)
export const updateOrderItemAsync = createAsyncThunk(
    "order/updateOrderItemAsync",
    async ({data, search}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, updateOrderItemApi(data))
            if (response.status === 200) {
                notificationErrorCustomMessage(`บันทึกข้อมูลแล้ว`, `success`)

                const s = {
                    ...search,
                    startDate: moment(search.startDate).format(`YYYY-MM-DD`),
                    endDate: moment(search.endDate).format(`YYYY-MM-DD`),
                }
                baseThunk.dispatch(getOrdersAsync(s))
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)
export const extendOrderItemAsync = createAsyncThunk(
    "order/extendOrderItemAsync",
    async ({id, search}, baseThunk) => {
        try {
            const response = await httpRequestFunc(baseThunk, extendOrders(id))
            if (response.status === 200) {
                notificationErrorCustomMessage(`บันทึกข้อมูลแล้ว`, `success`)

                const s = {
                    ...search,
                    startDate: moment(search.startDate).format(`YYYY-MM-DD`),
                    endDate: moment(search.endDate).format(`YYYY-MM-DD`),
                }
                baseThunk.dispatch(getOrdersAsync(s))
                return response.data
            }
        } catch (e) {
            console.log(e)
        }
    }
)
